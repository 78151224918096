import React, { useState, useEffect } from "react";
import { auth, googleProvider, db } from "../config/firebase.js";
import {
  getFirestore,
  query,
  where,
  doc,
  setDoc,
  collection,
  getDocs,
  addDoc,
  getDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Users from "./users.js";
import { useAuth } from "../../src/components/auth-email.js";
import ToggleSwitch from "../components/toggle.js";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
  LineChart,
} from "recharts";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import "..//pages/allCSS.css";
import UserPromptModal from "..//../src/components/userPrompt.js";

function IncentiveSearchCommercialista() {
  const { currentUser } = useAuth(); // Using a custom hook to get the current user
  const [atecoL3, setAtecoL3] = useState("");
  const [regione, setRegione] = useState("");
  const [firstName, setFirstName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [role, setRole] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyEmployees, setCompanyEmployees] = useState("");
  const [companyTurnover, setCompanyTurnover] = useState("");
  const [Descrizione, setDescrizione] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [incentives, setIncentives] = useState("");
  const [documents, setDocuments] = useState([]);
  const [incentivesCount, setIncentivesCount] = useState(0);
  const [totalIncentives, setTotalIncentives] = useState(0);
  const [totalFunding, setTotalFunding] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [incentivesData, setIncentivesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoadingIncentives, setIsLoadingIncentives] = useState(true);
  const [isLoadingFunding, setIsLoadingFunding] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const [cw, setCw] = useState(""); // Empty string as initial value or a default value "0" or "1"
  const [lastDataUpdate, setLastDataUpdate] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);
  const [newpiva, setNewpiva] = useState("");
  const [refreshData, setRefreshData] = useState(false); // State to trigger re-render
  const [dataChangeTrigger, setDataChangeTrigger] = useState(0);
  const [pivas, setPivas] = useState([]);
  const [selectedRegione, setSelectedRegione] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  const newEventRegioneOptions = [
    "Abruzzo",
    "Aosta",
    "Basilicata",
    "Calabria",
    "Campania",
    "Emilia Romagna",
    "Friuli Venezia Giulia",
    "Lazio",
    "Liguria",
    "Lombardia",
    "Marche",
    "Molise",
    "Piemonte",
    "Puglia",
    "Sardinia",
    "Sicilia",
    "Trentino/Alto Adige/Sudtirol",
    "Toscana",
    "Umbria",
    "Veneto",
  ];

  /*regione*/
  const handleRegionChange = (e) => {
    const selectedOption = e.target.value;
  
    // This will set the region to Lombardia if selected, or directly to the chosen option otherwise.
    setSelectedRegione(selectedOption);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////
  //// Create upload piva feature and create FB record
  
  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const userPivaCollection = collection(db, "commercialista", user.uid, user.uid);
        const snapshot = await getDocs(userPivaCollection);
        const pivaList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setPivas(pivaList);
      }
    };
    fetchData();
  }); // Dependency array includes the trigger

  const updateCommDb = async () => {
    console.log("Button clicked to update CommDb"); // Debug: Confirm when this is called
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (user) {
      try {
        console.log("Processing PIVA and regione:", newpiva, selectedRegione); // Debug: Check the values being processed
        const pivaArray = newpiva.split(',').map(piva => piva.trim());
  
        // Fetch existing PIVA documents to check the count
        const userPivaCollection = collection(db, "commercialista", user.uid, user.uid);
        const snapshot = await getDocs(userPivaCollection);
        const existingPivasCount = snapshot.docs.length;
  
        // Check if adding new pivas exceeds the maximum allowed
        if (existingPivasCount + pivaArray.length > 3) {
          console.error("Too many PIVAs requested, maximum available = 3");
          alert("Too many PIVAs requested, maximum available = 3"); // or use a more sophisticated error handling/display mechanism
          return; // Stop further execution
        }
  
        // If within the limit, proceed to add new pivas
        for (const piva of pivaArray) {
          const docRef = await addDoc(userPivaCollection, {
            piva: piva,
            regione: selectedRegione,
            timestamp: new Date()
          });
  
          console.log("Document added, now sending to backend"); // Debug: Confirm step before backend call
          const apiResponse = await sendPivaToBackend(piva, docRef.id);
          console.log("Backend response:", apiResponse); // Debug: Log backend response
  
          if (apiResponse.status === 'success') {
            await setDoc(doc(db, "commercialista", user.uid, user.uid, docRef.id), apiResponse.data, { merge: true });
            console.log("Firestore document updated successfully with API data.");
          } else {
            throw new Error("API data processing failed: " + apiResponse.message);
          }
        }
      } catch (error) {
        console.error("Error updating Firestore or communicating with the backend:", error);
      }
    } else {
      console.error("No user is signed in.");
    }
  };
  
  
  
  
  //////////////////////////////////////////////////////////////////////////////////////////////
  //// Run the api/piva script and save to the collection

  const sendPivaToBackend = async (piva, docId) => {
    console.log("Sending piva to backend:", piva);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    try {
      const payload = { piva, docId };
      const response = await fetch(`${API_BASE_URL}/api/piva`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload)
      });
      if (!response.ok) throw new Error("Network response was not ok");
      const responseData = await response.json();
      //console.log("Backend process triggered successfully:", responseData);
      return responseData; // Ensure this contains the status and data
    } catch (apiError) {
      console.error("API call failed:", apiError);
      return { status: "error", message: apiError.toString() };
    }
  };
  
  //////////////////////////////////////////////////////////////////////////////////////////////
  //// Display the aggregate results (all the businesses) and ability to filter for 1 piva

  
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchPivas = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
  
        if (user) {
          const userPivaCollection = collection(db, "commercialista", user.uid, user.uid);
          const q = query(userPivaCollection);
  
          try {
            const querySnapshot = await getDocs(q);
            const pivaList = querySnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            setPivas(pivaList);
            setLoading(false);
          } catch (error) {
            console.error("Error fetching PIVAs:", error);
            setLoading(false);
          }
        }
      };
  
      fetchPivas();
    }, []);
  
   /////////////////////////////////////////////////////////////////////////////////////////////
   /////table selector
   const [selectedPivaData, setSelectedPivaData] = useState(null);

   const handleRowSelect = (piva) => {
    console.log("Selected PIVA:", piva);
    setAtecoL3(piva.companyAteco);
    setRegione(piva.regione);
    setCompanySize(piva.companySize);
    setCompanyName(piva.companyName);
    setCompanyTurnover(piva.companyTurnover);
    setCompanyEmployees(piva.companyEmployees);
    fetchIncentivesData(piva.companyAteco, piva.regione, piva.companySize);

    // Set selected Piva data
    setSelectedPivaData({
      companyName: piva.companyName,
      companySize: piva.companySize,
      companyTurnover: piva.companyTurnover,
      companyAteco: piva.companyAteco,
      regione: piva.regione,
      piva: piva.piva,
    });
  };

  const fetchIncentivesData = async (atecoL3, regione, companySize) => {
    const extractBaseCode = (ateco) => {
      console.log("Original ATECO code:", ateco);
    
      // Updated regex to match two digits after the decimal
      // This regex captures two digits before the decimal and exactly two digits after the decimal
      const numericalPartMatch = ateco.match(/^(\d{2})\.(\d{2})/);
    
      if (numericalPartMatch) {
        const beforeDecimal = numericalPartMatch[1];  // e.g., "68"
        const afterDecimal = numericalPartMatch[2];   // e.g., "20"
    
        // Combine to get the required format: e.g., "68.20"
        return `${beforeDecimal}.${afterDecimal}`;
      }
    
      // Log an error or handle cases where the ATECO code format does not match expected
      console.log("ATECO code format is incorrect or not as expected.");
      return "";
    };
  
    const correctedRegione = regione === 'Sardinia' ? 'Sardegna' : regione;

    const requestBody = {
      codicel3: atecoL3 ? extractBaseCode(atecoL3) : "",
      regione: correctedRegione || "",
      companySize: companySize,
    };

    console.log("Sending to server:", requestBody);

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/incentives`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      let filteredData = await response.json();
      filteredData = filteredData.map((incentive) => ({ ...incentive, filtered: 1 }));
      localStorage.setItem("filteredData", JSON.stringify(filteredData));
      console.log("Incentives data received from server:", filteredData);
    } catch (error) {
      console.error("Error fetching incentives data:", error);
    }
  };
  

  //////////////////////////////////////////////////////////////////////////////////////////////
  //// Then repeat the same individual piva search - replace users db with commercialista_users
  useEffect(() => {
    const fetchUserData = async () => {
        if (currentUser && currentUser.email) {
            const usersCollectionRef = collection(db, "users");
            const q = query(
                usersCollectionRef,
                where("email", "==", currentUser.email)
            );
            try {
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const userData = querySnapshot.docs[0].data();
                    setFirstName(userData.firstName);
                } else {
                    console.log("No user data found for email:", currentUser.email);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        }
    };

    fetchUserData();
}, [currentUser]);  // Dependency array includes currentUser to refetch if it changes


  const [filters, setFilters] = useState({
    CW: "",
    Agevolazione: "",
    Fondo_perduto: "",
    Capitale_di_rischio: "",
    Prestito: "",
    Interventi_garanzia: "",
    Anticipo_rimborsabile: "",
  });

  const [usingFiltered, setUsingFiltered] = useState(false);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("filteredData")) || [];
    const filteredIds = storedData
      .filter(incentive => incentive.filtered === 1)
      .map(incentive => incentive.ID_Incentivo);
  
    if (filteredIds.length > 0) {
      fetchIncentiviByIds(filteredIds).then(fetchedIncentives => {
        setIncentives(fetchedIncentives);
      });
    } else {
      setIncentives([]);
    }
  }, [usingFiltered, filters, lastDataUpdate]);

  const fetchIncentiviByIds = async (ids) => {
    const db = getFirestore();
    const incentiviCollection = collection(db, "incentivi");
  
    const promises = ids.map((id) => {
      const docRef = doc(incentiviCollection, id);
      return getDoc(docRef);
    });
  
    try {
      const documentSnapshots = await Promise.all(promises);
      const documents = documentSnapshots
        .map((docSnapshot) =>
          docSnapshot.exists()
            ? { id: docSnapshot.id, ...docSnapshot.data() }
            : null
        )
        .filter((doc) => doc !== null); // Filtering out null values if document doesn't exist
  
      // Sort documents by Data_apertura in descending order
      documents.sort((a, b) => {
        const dateA = new Date(a.Data_apertura);
        const dateB = new Date(b.Data_apertura);
        return dateB - dateA; // descending order
      });
  
      return documents;
    } catch (error) {
      console.error("Error fetching documents by IDs:", error);
      return []; // Returning an empty array on error ensures the UI can handle this gracefully
    }
  };

  const handleFilterChange = (field, value) => {
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters, [field]: value };
      const currentFilteredData = JSON.parse(localStorage.getItem("filteredData")) || [];
      const updatedIncentives = currentFilteredData.map(incentive => {
        const shouldFilter = Object.keys(updatedFilters).every(key =>
          updatedFilters[key] === "" || incentive[key] === updatedFilters[key]
        );
  
        return {
          ...incentive,
          filtered: shouldFilter ? 1 : 0,
        };
      });
  
      localStorage.setItem("filteredData", JSON.stringify(updatedIncentives));
      setUsingFiltered(u => !u);  // Toggle to force re-render
      return updatedFilters;
    });
  };
  

  const [filteredCount, setFilteredCount] = useState(0); // State to store the count

  useEffect(() => {
    //console.log("Effect triggered by filters or data update");

    const storedData = JSON.parse(localStorage.getItem("filteredData")) || [];
    const filteredIds = storedData
      .filter((incentive) => incentive.filtered === 1)
      .map((incentive) => incentive.ID_Incentivo);

    setFilteredCount(filteredIds.length);
    //console.log("Updated filtered count:", filteredIds.length);

    if (filteredIds.length > 0) {
      fetchIncentiviByIds(filteredIds).then((fetchedIncentives) => {
        setIncentives(fetchedIncentives);
        //console.log("Incentives updated based on filtered IDs");
      });
    } else {
      setIncentives([]);
      //console.log("No filtered incentives to display");
    }
  }, [filters, lastDataUpdate]);

  ///////////////////////////////////////
  ///////reset filters when page mounts

  const resetFilters = () => {
    // Fetch the current filtered data from local storage
    const storedData = JSON.parse(localStorage.getItem("filteredData")) || [];

    // Set all 'filtered' properties to 1
    const updatedData = storedData.map((incentive) => ({
      ...incentive,
      filtered: 1, // Resetting all to visible
    }));

    // Update local storage with the modified data
    localStorage.setItem("filteredData", JSON.stringify(updatedData));

    // Update the state used to display incentives
    fetchIncentiviByIds(
      updatedData
        .filter((incentive) => incentive.filtered === 1)
        .map((incentive) => incentive.ID_Incentivo)
    ).then((fetchedIncentives) => {
      setIncentives(fetchedIncentives);
    });

    // Optionally, reset any filter states if being used to track current filters
    setFilters({
      CW: "",
      Agevolazione: "",
      Fondo_perduto: "",
      Capitale_di_rischio: "",
      Prestito: "",
      Interventi_garanzia: "",
      Anticipo_rimborsabile: "",
    });
  };

  useEffect(() => {
    // Call resetFilters on the initial load of the component
    resetFilters();
  }, []);


  const sendSearch = async (Descrizione, setLastDataUpdate) => {
    setIsLoading(true); // Start loading
    //console.log("Start sendSearch with Description:", Descrizione);

    const storedData = localStorage.getItem("filteredData");
    let incentives = storedData ? JSON.parse(storedData) : [];
    //console.log("Loaded incentives from localStorage:", incentives);

    const ids = incentives
      .filter((incentive) => incentive.filtered === 1)
      .map((incentive) => incentive.ID_Incentivo);
    //console.log("Filtered IDs to send:", ids);

    if (!currentUser || ids.length === 0) {
      console.error(
        "User information is missing or no incentive IDs available."
      );
      return;
    }

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${API_BASE_URL}/api/sendSearch`;
    const requestBody = {
      ids: ids,
      Descrizione: Descrizione,
    };

    //console.log("Sending request to server with body:", requestBody);

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      //console.log("Response data received:", responseData);

      if (!responseData.incentivi_info || responseData.incentivi_info.length === 0) {
        //console.log("Nessun incentivo trovato");
        alert("Nessun incentivo trovato"); // Alert user if no incentives are returned from the server
      } else {
        const returnedIDs = responseData.incentivi_info.map(
          (item) => item.ID_Incentivo
        );
        //console.log("Returned IDs from server:", returnedIDs);

        incentives = incentives.map((incentive) => ({
          ...incentive,
          filtered: returnedIDs.includes(incentive.ID_Incentivo) ? 1 : 0,
        }));
        //console.log("Updated incentives after response:", incentives);
        localStorage.setItem("filteredData", JSON.stringify(incentives));
        setLastDataUpdate(Date.now()); // Update state to trigger re-render
      }
    } catch (error) {
      console.error("Error sending search to server:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const formatDescription = (desc) => {
    if (typeof desc === "string") {
      desc = desc.trim(); // Trim whitespace at the start and end

      // Remove everything before and including the first colon on the first line
      desc = desc.replace(/^[^:]*:\s*/, "");

      // Normalize new lines and handle the initial bullet point
      let lines = desc.split(/\n+/); // Split the description into lines
      lines = lines.reduce((filteredLines, line, index) => {
        line = line.trim(); // Trim each line to remove leading/trailing whitespace

        // Skip empty lines to avoid adding extra spaces
        if (line === "") {
          return filteredLines;
        }

        // Handle numbered lines to not start with a bullet
        if (/^\d+\./.test(line)) {
          filteredLines.push(line); // Return the line as is if it starts with a number and a period
        } else if (index > 0 && filteredLines.length > 0) {
          // Apply bullets to lines that are not the first line and not after an empty line
          filteredLines.push("• " + line);
        } else {
          filteredLines.push(line); // First line or any line not fitting the above criteria gets no bullet
        }

        return filteredLines;
      }, []);

      // Join the lines back together, inserting a newline between each
      return lines.join("\n");
    }
    return "No description available"; // Return a default message if no description is provided
  };

  const getCategoryClass = (category) => {
    const categoryClasses = {
      "Crisi d'impresa": "crisi-impresa",
      Digitalizzazione: "digitalizzazione",
      "Imprenditoria femminile": "imprenditoria-femminile",
      "Innovazione e ricerca": "Innovazione-ricerca",
      "Sostegno investimenti": "Sostegno-investimenti",
      "Start up/Sviluppo d'impresa": "Startup-impresa",
      "Crisi d'impresa": "Crisi-impresa",
      "Inclusione sociale": "Inclusione-sociale",
      "Imprenditoria giovanile": "Imprenditoria-giovanile",
      Internazionalizzazione: "Internazionalizzazione",
      "Transizione ecologica": "Transizione-ecologica",
      // Add more mappings here...
    };
    return categoryClasses[category] || "default-category"; // Fallback class
  };

  const formatDate = (dateString) => {
    return dateString
      ? dateString.split("T")[0]
      : "Aperto fino a nuova communicazione"; // Default to 'N/A' if dateString is undefined
  };

  const formatCurrency = (value) => {
    if (value) {
      const millions = Number(value) / 1000000;
      return `€${millions.toFixed(2)}M`;
    }
    return "N/A"; // Default case if the value is not provided
  };

  const isYear2024 = (dateString) => {
    if (!dateString) return false; // Guard clause for undefined or empty input
    const year = new Date(dateString.split("T")[0]).getFullYear(); // Get the year from the date
    return year === 2024;
  };

  const placeholders = [
    "SMART&START",
    "PNRR",
    "CREDITO",
    "INTERNAZIONALE",
    "INFRASTRUTTURE",
    "TASSO ZERO",
    "AGEVOLAZIONI",
  ];
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      // Update the placeholder text by cycling through the placeholders array
      currentIndex = (currentIndex + 1) % placeholders.length;
      setCurrentPlaceholder(placeholders[currentIndex]);
    }, 1000); // Change placeholder every 2000 milliseconds (2 seconds)

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  //////////////////////////////////////////////////////////////////////////////
  /////////////////
  /// Nav to ThreadUnic
  const navigate = useNavigate();
  const [threadId, setThreadId] = useState("");
  const [currentThreadId, setCurrentThreadId] = useState(null);
 
  const startNewConversation = async (
    atecoL3,
    regione,
    assistant,
    incentiveId,
    firstName,
    companyName,
    companySize,
    companyEmployees,
    companyTurnover
  ) => {
    if (!selectedPivaData) {
      alert("Please select a piva before starting a new conversation.");
      return;
    }
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const userMessage = `Ciao, e questo incentivo buona per nostra azienda? Io sono ${firstName} e l'azienda si chiama ${companyName}. Siamo in ${regione} con ateco: ${atecoL3}. Siamo un ${companySize} con ${companyEmployees} dipendenti e turnover annuale di €${companyTurnover}.`;
      const response = await fetch(`${API_BASE_URL}/api/start_new_convo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: userMessage }),
      });
      //console.log("API Base URL:", API_BASE_URL);
      console.log("firstName:", firstName);
      console.log("companyName:", companyName);
      console.log("firstName:", regione);
      console.log("message:", userMessage);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      //console.log("Response data:", data); // Log the entire response data
      localStorage.setItem("currentThreadId", data.thread_id);
      //console.log("Assistant parameter:", assistant);
      //console.log("Stored thread_id in localStorage:", data.thread_id);

      if (data.thread_id && assistant) {
        //console.log("New thread ID:", data.thread_id, "assistant:", assistant);
        const title = `Discussion on ATECO ${atecoL3} in ${regione}`;
        // Navigate to the threadUnic page with the thread ID and assistant ID in the URL
        navigate(`/threadUnic/${assistant}/${data.thread_id}/${incentiveId}`, {
          state: { title: title },
        });
      } else {
        console.error(
          "Thread ID or Assistant ID was not returned from the server."
        );
      }
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    }
  };

  const uniquePivas = new Set(pivas.map(piva => piva.piva));
  /////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="searchy-container-comm">
      {!isAuthenticated && <UserPromptModal />}
      <h1 className="incentivy-container-comm2">
      Commercialista e Professionista Portal </h1>
      <div>
        <div className="incentivy-container-comm">
      <div>
        <h2> Per aggiungere un'altra impresa al tuo portafoglio, inserisci la partita IVA e la regione e seleziona GO
        </h2>
        <div style={{ display: "block", alignItems: "left" }} className="container-searchi">
    Partita IVA:
    <input
        className="input-field-searchi boxes"
        type="text"
        placeholder="Aggiungi un altro Partita Iva"
        value={newpiva}
        onChange={(e) => setNewpiva(e.target.value)}
    />
</div>

<div className="container-searchi">
  Regione:
  <select
    className="select-dropdown-searchi"
    value={selectedRegione}
    onChange={handleRegionChange}
  >
    <option value="defaultRole">Sceglie un regione</option>
    {newEventRegioneOptions.map((regione, index) => (
      <option key={index} value={regione}>{regione}</option>
    ))}
  </select>
</div>

<button
  type="button" 
  onClick={updateCommDb}
  style={{
    background: "#092f7a",
    color: "white",
    border: "1px solid white",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
    textAlign: "center",
  }}
>
  Go
</button>
</div>
</div>
<div className="agent2-containers-comm">
<div className="incentivy-container-comm">
<h1>Portfolio: {uniquePivas.size} imprese</h1>
  {pivas.length > 0 ? (
    <table style={{ width: '100%', borderCollapse: 'collapse'}}>
      <thead>
        <tr>
          <th style={{ color: 'white', border: '1px solid black', padding: '8px', textAlign: 'left' }}>PIVA</th>
          <th style={{ color: 'white', border: '1px solid black', padding: '8px', textAlign: 'left' }}>Nome</th>
          <th style={{ color: 'white', border: '1px solid black', padding: '8px', textAlign: 'left' }}>Regione</th>
          <th style={{ color: 'white', border: '1px solid black', padding: '8px', textAlign: 'left' }}>Size</th>
          <th style={{ color: 'white', border: '1px solid black', padding: '8px', textAlign: 'left' }}>Ateco</th>
        </tr>
      </thead>
      <tbody>
        {pivas.map(piva => (
          <tr key={piva.id} onClick={() => handleRowSelect(piva)}>
            <td style={{ color: 'white', border: '1px solid black', padding: '8px', cursor: 'pointer' }}>{piva.piva}</td>
            <td style={{ color: 'white', border: '1px solid black', padding: '8px', cursor: 'pointer' }}>{piva.companyName}</td>
            <td style={{ color: 'white', border: '1px solid black', padding: '8px', cursor: 'pointer' }}>{piva.regione}</td>
            <td style={{ color: 'white', border: '1px solid black', padding: '8px', cursor: 'pointer' }}>{piva.companySize}</td>
            <td style={{ color: 'white', border: '1px solid black', padding: '8px', cursor: 'pointer' }}>{piva.companyAteco}</td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <div>No PIVA records found.</div>
  )}
 {selectedPivaData && (
        <div style={{ marginTop: "20px", fontSize: "18px", padding: "10px", border: "1px solid #ccc", borderRadius: "8px", background: "#092f7a", color: "yellow" }}>
          <h3>Selected Piva Details</h3>
          <p><strong>Company Name:</strong> {selectedPivaData.companyName}</p>
          <p><strong>partita iva:</strong> {selectedPivaData.piva}</p>
        </div>
      )}
  <h2> Selezionare una impresa sopra
        </h2>
</div>
</div>
        <div className="agent2-containers-comm">
          <div className="incentivy-container-comm">
          <button className="button-searchi" onClick={resetFilters}>Refresh Data</button>
            <h1
              style={{
                margin: "10px 0 20px 0",
                color: "#092f7a",
                textAlign: "center",
                color: "white",
              }}
            >
              <div>
               Questa impresa ha {filteredCount} incentivi dedicati. 
               <div style={{fontSize: "20px"}}>Stiamo filtrando per regione, ateco
                e dimensione aziendale...
                </div>
              </div>
            </h1>
            <div 
              style={{ fontSize: "20px", color: "white", marginTop: "25px"}}
            >
              FILTRI
            </div>
            <div
              style={{
                display: "flex", // Enables flexbox
                flexDirection: "column", // Stacks children vertically
                padding: "20px",
                color: "white",
                alignItems: "center", // Aligns children at the start of the flex container
              }}
            >
              <ToggleSwitch
                label="Settimana Corrente"
                onChange={(value) => handleFilterChange("CW", value)}
              />
              <ToggleSwitch
                label="Agevolazione"
                onChange={(value) => handleFilterChange("Agevolazione", value)}
              />
              <ToggleSwitch
                label="Fondo Perduto"
                onChange={(value) => handleFilterChange("Fondo_perduto", value)}
              />
              <ToggleSwitch
                label="Capitale di rischio"
                onChange={(value) =>
                  handleFilterChange("Capitale_di_rischio", value)
                }
              />
              <ToggleSwitch
                label="Prestito"
                onChange={(value) => handleFilterChange("Prestito", value)}
              />
              <ToggleSwitch
                label="Interventi Garanzia"
                onChange={(value) =>
                  handleFilterChange("Interventi_garanzia", value)
                }
              />
              <ToggleSwitch
                label="Anticipo rimborsabile"
                onChange={(value) =>
                  handleFilterChange("Anticipo_rimborsabile", value)
                }
              />
            </div>
          </div>
        </div>

        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
            fontSize: "16px",
          }}
        >
          <div
            className="incentivy-container-comm"
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              color: "#092f7a",
              padding: "10px",
              textAlign: "center",
            }}
          >
            <h1 style={{ fontSize: "20px", marginTop: "20px", color: "white" }}>
              Keywords Ricerca
            </h1>
            <div>
              <input
                type="text"
                placeholder={currentPlaceholder}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                  width: "300px",
                  marginRight: "10px",
                  height: "40px",
                  fontSize: "18px",
                }}
              />
              <button
                className="button-stylish"
                onClick={() => sendSearch(description, setLastDataUpdate)}
                disabled={isLoading} // Disable button while loading
              >
                VAI
              </button>
              {isLoading && <div className="spinner-sendsearch"></div>}
            </div>
          </div>
          <div>
            <div style={{ textAlign: "left", fontSize: "16px" }}>
              <div>
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                {/* {responseMessage && formatResponseMessage(responseMessage)} */}
              </div>
            </div>
            <div>
              <div
                className="incentivy-container-comm3"
                
              >
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                <h1
                  style={{
                    marginTop: "20px",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Incentivi Dedicati
                </h1>
                {incentives.length > 0 ? (
                  <ul style={{ padding: "10px", listStyleType: "none" }}>
                    {incentives.map((doc, index) => (
                      <li
                        key={index}
                        style={{
                          marginBottom: "20px",
                          border: "1px solid #ccc",
                          padding: "20px",
                          borderRadius: "8px",
                          boxShadow:
                            "0 4px 6px rgba(0,0,0,0.1), 5px 5px 5px #FF5758",
                          backgroundColor: "#fff", // adds a clean white background
                          lineHeight: "2rem",
                          fontSize: "20px",
                          position: "relative", // Needed for absolute positioning of the arrow
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "2px",
                            right: "10px",
                          }}
                        >
                          <button
                              className="button-stylish"
                              onClick={() => {
                                //console.log("Document data:", doc); // Log the entire document
                                const assistant = doc.AssistantID; // Rename AssistantID to assistant
                                startNewConversation(
                                  atecoL3,
                                  regione,
                                  assistant,
                                  doc.ID_Incentivo,
                                  firstName,
                                  companyName,
                                  companySize,
                                  companyEmployees,
                                  companyTurnover
                                );
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              →
                            </button>
                        </div>
                        {isYear2024(doc.Data_apertura) && (
                          <button
                          className="button-stylish"
                          onClick={() => {
                            //console.log("Document data:", doc); // Log the entire document
                            const assistant = doc.AssistantID; // Rename AssistantID to assistant
                            startNewConversation(
                              atecoL3,
                              regione,
                              assistant,
                              doc.ID_Incentivo,
                              firstName,
                              companyName,
                              companySize,
                              companyEmployees,
                              companyTurnover
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        >
                            NUOVO
                          </button>
                        )}
                        <h2 style={{ marginTop: "20px" }}>{doc.Titolo}</h2>
                        <strong>
                          {doc.KeyBenefit} <br />
                        </strong>
                        <strong>Apertura:</strong>{" "}
                        {formatDate(doc.Data_apertura)}{" "}
                        <strong>Chiusura:</strong>{" "}
                        {formatDate(doc.Data_chiusura)}
                        <br />
                        <div>
                          {doc.Obiettivo_Finalita.map((goal) => (
                            <span
                              key={goal}
                              className={`category-box ${getCategoryClass(
                                goal
                              )}`}
                            >
                              {goal}
                            </span>
                          ))}
                        </div>
                        <pre className="description-box">
                          {formatDescription(doc.Summary)}
                        </pre>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>Aggiungi KEYWORDS</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncentiveSearchCommercialista;