import React from 'react';

const DisclaimerCampus = () => {
	return (
		<div>
			<h1 style={{fontSize: '14px', padding: '20px'}}>
Event Ticket Purchase Terms and Conditions: Disclaimer and Limitation of Liability
<br />
<br />
1. No Responsibility for Event Occurrence: LunaParque acts solely as an intermediary between event organizers and customers. We are not responsible for the actual occurrence of events. The existence of an event listing on our platform does not guarantee that the event will take place as scheduled. We recommend customers to verify event status with the organizers.
<br />
2. No Liability for Safety: Customers' safety at events is paramount. However, LunaParque does not organize the events and thus cannot take liability for the safety and security of customers attending these events. Attendees should follow all safety guidelines and instructions provided by the event organizers and venue operators.
<br />
3. Service Provision: LunaParque provides advertising space for events and a booking service for tickets on behalf of event organizers. We do not host, endorse, or directly organize any of the events listed on our platform.
<br />
4. Event Availability and Refunds: While we strive to provide accurate ticket availability information, in rare instances, events may become sold out after a ticket purchase is made. In such cases, LunaParque will issue a full refund to the customer within 14 days of the purchase.
<br />
5. Cancellation Policy: Our standard cancellation policy allows for a 50% refund for ticket cancellations made more than one month before the start of the event. No refunds will be provided for cancellations made within one month of the event start date. Please note, some events may have their own cancellation policies, which will be clearly indicated at the time of booking. Customers are advised to review these policies carefully before making a purchase.
<br />
Additional Terms
<br />
<br />
- Accuracy of Information: LunaParque does not guarantee the accuracy, completeness, or reliability of the information provided by event organizers, including dates, times, locations, and content of the events. Customers are encouraged to confirm this information before making any decisions related to event attendance.
<br />
- Changes to Events: Events are subject to change or cancellation by the organizers at any time without prior notice. LunaParque will not be liable for any changes, cancellations, or any other adjustments made by event organizers.
<br />
- Use of Tickets: Tickets purchased through LunaParque are for personal use only and may not be resold or transferred without the express permission of the event organizers. Violation of this policy may result in the cancellation of the ticket without refund.
<br />
- Indemnification: By purchasing tickets through LunaParque, customers agree to indemnify and hold LunaParque harmless from any and all claims, liabilities, damages, and expenses arising from their attendance at events.
<br />
<br />
This agreement is subject to change at the discretion of LunaParque. Continued use of our service following any changes signifies acceptance of the revised terms.
<br />
				
			</h1>
		</div>
	);
};

export default DisclaimerCampus;
