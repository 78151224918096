import React, { useEffect, useState, useRef } from 'react';

function Typewriter({ text, speed }) {
    const [displayedText, setDisplayedText] = useState('');
    const currentIndex = useRef(0); // Track the current index of text being displayed

    useEffect(() => {
        // Only start the interval if new text is being added
        if (text.length > currentIndex.current) {
            const intervalId = setInterval(() => {
                if (currentIndex.current < text.length) {
                    const nextChar = text[currentIndex.current];
                    setDisplayedText(prev => prev + nextChar);
                    currentIndex.current++;
                } else {
                    clearInterval(intervalId);
                }
            }, speed);
            
            return () => clearInterval(intervalId);
        }
    }, [text, speed]); // Dependency on text and speed to adjust to new input

    return <div>{displayedText}</div>;
}

export default Typewriter;
