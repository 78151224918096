///imports
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { db, auth } from "../config/firebase.js";
import {
  getDocs,
  getDoc,
  collection,
  addDoc,
  serverTimestamp,
  orderBy,
  where,
  query,
  doc,
  updateDoc,
  limit,
  startAfter,
  onSnapshot,
} from "firebase/firestore";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import "..//pages/allCSS.css";
import UserPromptModal from "../components/userPrompt.js";
import App from "../App.js";
import { useParams } from "react-router-dom";
import IncentiveSearch from "./agent2.js";
import Typewriter from "../components/Typewriter.js";
import SSEDisplay from "../components/SSEDisplay.js";
import renderCriteria from "../components/criteria.js";
//import LongPollDisplay from '..//components/LongPolling.js';

function Preapply() {
  //console.log(threadId); // This should log the actual threadId from the URL
  const navigate = useNavigate();
  const headerStyles = {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    padding: "10px",
    fontFamily: "'Poppins', sans-serif",
    textAlign: "center",
    wordSpacing: "6px",
    fontSize: "35px",
    //animation: "flash 3s infinite",
  };

  ////////////////////////////////////////////////////////////////////////////////////
  //text entry to fb db
  const [newQ1, setNewQ1] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const questionsCollectionRef = collection(db, "questions");
  const threadsCollectionRef = collection(db, "threads");
  const [lastVisible, setLastVisible] = useState(null);
  const location = useLocation();
  const { title } = location.state || { title: "Default Title" };
  const { assistant, threadId, incentiveId } = useParams();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [document, setDocument] = useState(null);

  ///////////////////
  /////get Incentivi details to start with

  useEffect(() => {
    const fetchDocument = async () => {
      const incentiviCollectionRef = collection(db, "incentivi");
      const incentiveQuery = query(
        incentiviCollectionRef,
        where("AssistantID", "==", assistant),
        where("ID_Incentivo", "==", incentiveId)
      );
      setLoading(true);
      try {
        const querySnapshot = await getDocs(incentiveQuery);
        if (!querySnapshot.empty) {
          // Assuming you are interested in the first matched document
          setDocument(querySnapshot.docs[0].data());
        } else {
          console.log("No such document!");
          setError("Document not found");
        }
      } catch (err) {
        console.error("Error getting document:", err);
        setError("Failed to fetch data");
      }
      setLoading(false);
    };

    fetchDocument();
  }, [assistant, incentiveId]);

  //////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    // Call getQuestionList when the component mounts
    getQuestionList();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  // The rest of your component...

  const getQuestionList = async () => {
    try {
      const userId = auth.currentUser ? auth.currentUser.uid : null;
      if (!userId) {
        console.error("User is not authenticated.");
        return;
      }

      // Create a query against the collection.
      const q = query(
        questionsCollectionRef,
        where("userId", "==", userId),
        orderBy("timestamp", "asc")
      );
      const querySnapshot = await getDocs(q);

      const filteredData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setQuestionList(filteredData);
    } catch (err) {
      console.error("Error fetching questions:", err);
    }
  };

  const onSubmitQuestion = async () => {
    try {
      if (!newQ1.trim()) {
        console.log("Empty question, exiting.");
        alert("Please enter a message.");
        return;
      }

      const userId = auth.currentUser ? auth.currentUser.uid : null;
      //console.log("Current user ID:", userId);
      if (!userId) {
        console.error("User is not authenticated.");
        return;
      }

      //console.log("Parameters:", { assistant, threadId });
      //console.log("Database references:", { questionsCollectionRef, threadsCollectionRef });

      // Add the question to Firestore and get the reference to the newly added document
      //console.log("Attempting to add a document to questions collection...");
      const docRef = await addDoc(questionsCollectionRef, {
        q1: newQ1,
        userId: userId,
        timestamp: serverTimestamp(),
        threadId: threadId, // Placeholder for the response
        response: "",
      });

      console.log("Document added with ID:", docRef.id);
      localStorage.setItem("currentDocId", docRef.id); // Store docId in local storage

      //console.log("Attempting to add a document to threads collection...");
      const docReff = await addDoc(threadsCollectionRef, {
        userId: userId,
        threadId: threadId,
        title: title,
        assistant: assistant,
      });

      //console.log("Document added with ID:", docReff.id);

      // Call the API after successfully adding the question
      await fetchCompletion(newQ1, threadId, assistant, docRef.id);
      //checkForResponse(threadId, docRef.id);

      //getResponse(threadId, docRef.id);

      setNewQ1("");

      // Optionally refresh the question list to include the latest question
      await getQuestionList();
    } catch (err) {
      console.error("Error submitting the question:", err);
    }
  };

  const [showUserPrompt, setShowUserPrompt] = useState(false);
  useEffect(() => {
    const userHasVisited = localStorage.getItem("userHasVisited");
    if (!userHasVisited) {
      setShowUserPrompt(true);
    }
  }, []);
  const handleClose = () => {
    localStorage.setItem("userHasVisited", "true");
    setShowUserPrompt(false);
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);


  /////////////////////////////////////////////////////////////////////////////////////
  //contact us for help

  const sendContactEmail = async () => {
    const user = auth.currentUser;
    if (!user) {
      console.error("User is not authenticated.");
      return;
    }
 
    const userEmail = user.email;

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/contactus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sender_email: userEmail, incentive_id: incentiveId }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result.message);
        navigate("/search");
      } else {
        const error = await response.json();
        console.error("Error sending email:", error.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////

  const [question, setQuestion] = useState("");
  const [docId, setDocId] = useState("");
  //const [jsonData, setJsonData] = useState(Opendata); // Ensure Opendata is defined
  const [apiResponse, setApiResponse] = useState("");
  const [messages, setMessages] = useState([
    {
      role: "system",
      content:
        "You are a helpful assistant with good general knowledge for checking facts",
      file_ids: "123",
    },
  ]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  /////message back and forth logic

  // Similar as before, sends the question to your Flask backend
  const fetchCompletion = async (question, threadId, assistant, docId) => {
    console.log(
      "Sending question to server:",
      question,
      threadId,
      assistant,
      docId
    );

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${API_BASE_URL}/api/fetchCompletionServer`;

    try {
      // Create the request body directly within the fetch call for clarity and simplicity
      const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          question: question,
          threadId: threadId,
          assistant: assistant,
          docId: docId,
        }),
      });

      console.log(
        "Request body:",
        JSON.stringify({
          question: question,
          threadId: threadId,
          assistant: assistant,
          docId: docId,
        })
      );

      console.log("Response received. Status:", response.status);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseText = await response.text(); // Fetch the raw response text
      console.log("Raw response text:", responseText);

      try {
        const jsonResponse = JSON.parse(responseText);
        console.log("Parsed JSON response:", jsonResponse);

        const { thread_id } = jsonResponse;
        console.log("Received thread_id & assistant:", thread_id, assistant);

        // Optionally update the document with the new thread ID if necessary
        if (!threadId) {
          await updateDoc(doc(questionsCollectionRef, docId), {
            threadId: thread_id,
          });
          console.log("Updated document with new threadId.");
        }

        if (!assistant) {
          await updateDoc(doc(threadsCollectionRef, docId), {
            assistant: assistant,
          });
          console.log("Updated document with new assistant.");
        }

        // SSEDisplay(thread_id, docId);
        console.log("Ready to display SSE stream.");
      } catch (parseError) {
        console.error("Error parsing JSON:", parseError);
      }
    } catch (error) {
      console.error("Error during fetch operation:", error);
    }
  };

  const textAreaRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    //console.log("Submitting question:", newQ1);

    try {
      await onSubmitQuestion();
      // Reset the textarea's height directly using the ref
      if (textAreaRef.current) {
        textAreaRef.current.style.height = "auto";
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  const [currentThreadId, setCurrentThreadId] = useState(null);
  const [lastThreads, setLastThreads] = useState([]);

  const filteredQuestions = questionList.filter(
    (question) => question.threadId === threadId
  );

  const [isLoading, setIsLoading] = useState(false);

  const [threadTitle, setThreadTitle] = useState("");

  useEffect(() => {
    const fetchThreadTitle = async () => {
      if (threadId) {
        // Ensure threadId is not undefined or empty
        const threadsCollectionRef = collection(db, "threads");
        const q = query(
          threadsCollectionRef,
          where("threadId", "==", threadId)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Assuming each threadId value is unique and only one document should match
          const docSnap = querySnapshot.docs[0];
          setThreadTitle(docSnap.data().title); // Update state with the fetched title
        } else {
          //console.log("No such document with threadId:", threadId);
        }
      }
    };

    fetchThreadTitle();
  }, [threadId]); // Re-fetch whenever threadId changes

  //////////////////////////////////////////////////////////////////////////////////////////////////
  ///////FORMATTING
  const formatDescription = (desc) => {
    //console.log("Original description:", desc); // Log the initial input
    if (typeof desc === "string") {
      desc = desc.trim(); // Trim whitespace at the start and end
      //console.log("Trimmed description:", desc); // Check the trimmed description

      let lines = desc.split(/\n+/);
      lines = lines.reduce((filteredLines, line, index) => {
        line = line.trim();
        //console.log("Processing line:", line); // Log each line as processed

        if (line === "") {
          return filteredLines;
        }

        if (/^\d+\./.test(line)) {
          filteredLines.push(line);
        } else if (index > 0 && filteredLines.length > 0) {
          filteredLines.push(" " + line);
        } else {
          filteredLines.push(line);
        }

        return filteredLines;
      }, []);

      //console.log("Formatted lines:", lines.join('\n')); // Log the final formatted text
      return lines.join("\n");
    }
    return "No description available";
  };

  const formatDate = (dateString) => {
    return dateString ? dateString.split("T")[0] : "N/A"; // Default to 'N/A' if dateString is undefined
  };

  ////////////////////////////////////////////////////////////////////////////////////////

  const [criteriaStates, setCriteriaStates] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const allSelectedSi = Object.values(criteriaStates).every(
      (state) => state === "Si"
    );
    setShowPopup(allSelectedSi);
  }, [criteriaStates]);

  const handleButtonClick = (
    buttonType,
    criterionKey,
    criterionDescription
  ) => {
    if (buttonType === "?") {
      const fullText = `cosa significa esattamente questo criterio: ${criterionDescription}`;
      setNewQ1(fullText);
      if (textAreaRef.current) {
        textAreaRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      // Assuming handleSubmit takes an event argument
      handleSubmit(new Event("submit"));
    } else {
      setCriteriaStates((prevStates) => ({
        ...prevStates,
        [criterionKey]: buttonType,
      }));
    }
  };

  localStorage.removeItem("currentThreadId");

  ////////////////////////// FORMATTING EXTRA
  const currentDateTime = new Date();

  const applySpecialStyles = (text) => {
    const specialPhrases = [
      "Portale per la Domanda:",
      "Documenti Necessari:",
      "Facsimile della Domanda:",
    ];
    const specialStyle = {
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "2.5",
    };

    if (!text) return null; // Return null or an empty React fragment if text is undefined

    return text
      .split(
        /(\bPortale per la Domanda:|\bDocumenti Necessari:|\bFacsimile della Domanda:\b)/
      )
      .map(
        (part, index) =>
          specialPhrases.includes(part.trim()) ? (
            <span key={index} style={specialStyle}>
              {part}
            </span>
          ) : (
            <span key={index} style={{ fontWeight: "normal" }}>
              {part}
            </span>
          ) // Default style
      );
  };

  const formatText = (text) => {
    if (!text) return ""; // Check for null or undefined or empty strings

    let formattedText = text.replace(/\*\*\d+\.\)?\s?/g, "\n\n"); // Handle headings
    formattedText = formattedText.replace(/\-\*/g, "\n"); // Handle list items
    formattedText = formattedText.replace(/\*/g, ""); // Remove asterisks
    return formattedText;
  };

  const formattedHowToApply = formatText(document?.HowToApply);

  // Split the formatted text by new lines and map to paragraphs
  const paragraphs = formattedHowToApply.split("\n").map((paragraph, index) => {
    if (paragraph.trim() === "") return null; // Avoid rendering empty paragraphs

    return (
      <p key={index} style={{ fontWeight: "bold" }}>
        {applySpecialStyles(paragraph)}
      </p>
    );
  });

  return (
    <div className="threadunic-container">
      {/*   <button
    onClick={() => navigate("/search")}
    className="threadunic-button"
  >
    🔙
  </button> */}
      <div
        style={{
          position: "relative",
          textAlign: "left",
          border: "none",
          marginRight: "30px",
          marginTop: "10px",
        }}
      >
        <div>
          <div>
            <p>{currentDateTime.toLocaleString()}</p>{" "}
            {/* Displaying date and time in local format */}
          </div>
          <div>
            {document ? (
              <div>
                <h1 style={{ fontWeight: "bold" }}>
                  <strong>Pre-Application: </strong> {document.Titolo}
                </h1>
              </div>
            ) : (
              <p>No data available.</p>
            )}
          </div>
        </div>
        <div>
          {!isAuthenticated && <UserPromptModal />}
          <div
            style={{
              margin: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          ></div>
          <div>
            {document ? (
              <div className="incentivethread-details">
                <div>{paragraphs}</div>
                {/*       <p style={{fontWeight:"bold"}}><strong> </strong> {document.HowToApply}</p> */}
              </div>
            ) : (
              <p>No data available.</p>
            )}
          </div>
        </div>
        <div className="App">
          {/*   <SimpleQuestionDisplay threadId={threadId} /> */}
        </div>
        {/* <div className="full-display">
          <div className="questions-display">
            {filteredQuestions.map((question, index) => {
              // Convert timestamp to JavaScript Date object
              const questionDate = question.timestamp?.toDate();

              // Format the date as a simple string for comparison (ignoring time part)
              const questionDateString = questionDate?.toLocaleDateString();

              // Check if this is the first question of a new day
              const isFirstQuestionOfDay =
                index === 0 ||
                filteredQuestions[index - 1]?.timestamp
                  ?.toDate()
                  ?.toLocaleDateString() !== questionDateString;

              const isLastQuestion = index === filteredQuestions.length - 1;

              return (
                <div key={question.id} className="question-item">
                
                  {isFirstQuestionOfDay && (
                    <div className="date-display">
                      <small>{questionDateString}</small>
                    </div>
                  )}
                  <div className="question-text">
                    <div style={{ lineHeight: "1.6" }}>
                      <strong>User:</strong> {question.q1}
                    </div>
                  </div>
                  <div
                    className="generic-response"
                    style={{ lineHeight: "1.6", marginTop: "20px" }}
                  >
                    <strong>Agent:</strong>{" "}
                    {isLastQuestion && (
                      <SSEDisplay
                        threadId={question.threadId}
                        docId={question.id}
                        setApiResponse={setApiResponse}
                        setIsLoading={setIsLoading}
                      />
                    )}
                    {!isLastQuestion && question.response && (
                      <div>
                        {question.response
                          .split(". ")
                          .map((sentence, idx, arr) => (
                            <p key={idx}>
                              {sentence + (idx < arr.length - 1 ? "." : "")}
                            </p>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div> */}

        <div>
          {/* <div className="input-container">
            <form onSubmit={handleSubmit} className="input-container">
              <textarea
                ref={textAreaRef}
                placeholder="Fare una domanda sul processo di candidatura..."
                onChange={(e) => setNewQ1(e.target.value)}
                value={newQ1}
                className="chatbox-style"
                maxLength="1000"
                required
                rows="1"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
                onInput={(e) => {
                  if (textAreaRef.current) {
                    textAreaRef.current.style.height = "auto";
                    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
                  }
                }}
              />

              <button type="submit" className="submit-button">
                ➡️
              </button>
            </form>
          </div> */}
          <div className="threadunic-container" style={{marginTop: "30px", fontSize: "40px", textAlign: "center"}}>
          Avvisaci di inviare la pre-richiesta per la revisione
          <button onClick={sendContactEmail} className="other-button">
            Contattaci
          </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preapply;
