///imports
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { db, auth } from "../config/firebase.js";
import {
  getFirestore,
  getDocs,
  getDoc,
  collection,
  addDoc,
  serverTimestamp,
  orderBy,
  where,
  query,
  doc,
  updateDoc,
  limit,
  startAfter,
  onSnapshot,
} from "firebase/firestore";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import "..//pages/allCSS.css";
import UserPromptModal from "../components/userPrompt.js";
import App from "../App.js";
import { useParams } from "react-router-dom";
import IncentiveSearch from "./agent2.js";
import Typewriter from "../components/Typewriter.js";
import SSEDisplay from "../components/SSEDisplay.js";
import renderCriteria from "../components/criteria.js";
import { incentiviCollectionRef } from "./home.js";
//import LongPollDisplay from '..//components/LongPolling.js';
import ToggleSwitch from "../components/toggle.js";
import { useAuth } from "../../src/components/auth-email.js";

function IncentiveUnic() {
  const { currentUser } = useAuth(); // Using a custom hook to get the current user
  const [atecoL3, setAtecoL3] = useState("");
  const [regione, setRegione] = useState("");
  const [firstName, setFirstName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyEmployees, setCompanyEmployees] = useState("");
  const [companyTurnover, setCompanyTurnover] = useState("");
  const [Descrizione, setDescrizione] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [incentives, setIncentives] = useState("");
  const [documents, setDocuments] = useState([]);
  const [incentivesCount, setIncentivesCount] = useState(0);
  const [totalIncentives, setTotalIncentives] = useState(0);
  const [totalFunding, setTotalFunding] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [incentivesData, setIncentivesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoadingIncentives, setIsLoadingIncentives] = useState(true);
  const [isLoadingFunding, setIsLoadingFunding] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const [cw, setCw] = useState(""); // Empty string as initial value or a default value "0" or "1"
  const [lastDataUpdate, setLastDataUpdate] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);
  const { assistant, incentiveId } = useParams();
  const [document, setDocument] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  useEffect(() => {
    const fetchDocument = async () => {
      const incentiviCollectionRef = collection(db, "incentivi");
      //console.log("Fetching document with assistant:", assistant, "and incentiveId:", incentiveId); // Log assistant and incentiveId
      const incentiveQuery = query(
        incentiviCollectionRef,
        where("AssistantID", "==", assistant),
        where("ID_Incentivo", "==", incentiveId)
      );
      try {
        const querySnapshot = await getDocs(incentiveQuery);
        if (!querySnapshot.empty) {
          //console.log("Document(s) found:", querySnapshot.docs.map(doc => doc.data()));
          // Assuming you are interested in the first matched document
          setDocument(querySnapshot.docs[0].data());
        } else {
          console.log("No such document!");
        }
      } catch (err) {
        console.error("Error getting document:", err);
      }
    };

    fetchDocument();
  }, [assistant, incentiveId]);
  
  useEffect(() => {
    async function fetchUserData() {
      if (currentUser && currentUser.email) {
        const usersCollectionRef = collection(db, 'users');
        const q = query(usersCollectionRef, where('email', '==', currentUser.email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          setAtecoL3(userData.companyAteco);
          setRegione(userData.regione);
          setFirstName(userData.firstName);
          setCompanyName(userData.companyName);
          setCompanySize(userData.companySize);
          setCompanyEmployees(userData.companyEmployees);
          setCompanyTurnover(userData.companyTurnover);
        } else {
          console.log('No user data found for email:', currentUser.email);
        }
      }
    }

    fetchUserData();
  }, [currentUser]);

  const formatDescription = (desc) => {
    if (typeof desc === "string") {
      desc = desc.trim(); // Trim whitespace at the start and end

      // Remove everything before and including the first colon on the first line
      desc = desc.replace(/^[^:]*:\s*/, "");

      // Normalize new lines and handle the initial bullet point
      let lines = desc.split(/\n+/); // Split the description into lines
      lines = lines.reduce((filteredLines, line, index) => {
        line = line.trim(); // Trim each line to remove leading/trailing whitespace

        // Skip empty lines to avoid adding extra spaces
        if (line === "") {
          return filteredLines;
        }

        // Handle numbered lines to not start with a bullet
        if (/^\d+\./.test(line)) {
          filteredLines.push(line); // Return the line as is if it starts with a number and a period
        } else if (index > 0 && filteredLines.length > 0) {
          // Apply bullets to lines that are not the first line and not after an empty line
          filteredLines.push("• " + line);
        } else {
          filteredLines.push(line); // First line or any line not fitting the above criteria gets no bullet
        }

        return filteredLines;
      }, []);

      // Join the lines back together, inserting a newline between each
      return lines.join("\n");
    }
    return "No description available"; // Return a default message if no description is provided
  };

  const getCategoryClass = (category) => {
    const categoryClasses = {
      "Crisi d'impresa": "crisi-impresa",
      Digitalizzazione: "digitalizzazione",
      "Imprenditoria femminile": "imprenditoria-femminile",
      "Innovazione e ricerca": "Innovazione-ricerca",
      "Sostegno investimenti": "Sostegno-investimenti",
      "Start up/Sviluppo d'impresa": "Startup-impresa",
      "Crisi d'impresa": "Crisi-impresa",
      "Inclusione sociale": "Inclusione-sociale",
      "Imprenditoria giovanile": "Imprenditoria-giovanile",
      Internazionalizzazione: "Internazionalizzazione",
      "Transizione ecologica": "Transizione-ecologica",
      // Add more mappings here...
    };
    return categoryClasses[category] || "default-category"; // Fallback class
  };

  const formatDate = (dateString) => {
    return dateString
      ? dateString.split("T")[0]
      : "Aperto fino a nuova communicazione"; // Default to 'N/A' if dateString is undefined
  };

  const formatCurrency = (value) => {
    if (value) {
      const millions = Number(value) / 1000000;
      return `€${millions.toFixed(2)}M`;
    }
    return "N/A"; // Default case if the value is not provided
  };

  const isYear2024 = (dateString) => {
    if (!dateString) return false; // Guard clause for undefined or empty input
    const year = new Date(dateString.split("T")[0]).getFullYear(); // Get the year from the date
    return year === 2024;
  };

  const placeholders = [
    "SMART&START",
    "PNRR",
    "CREDITO",
    "INTERNAZIONALE",
    "INFRASTRUTTURE",
    "TASSO ZERO",
    "AGEVOLAZIONI",
  ];
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      // Update the placeholder text by cycling through the placeholders array
      currentIndex = (currentIndex + 1) % placeholders.length;
      setCurrentPlaceholder(placeholders[currentIndex]);
    }, 1000); // Change placeholder every 2000 milliseconds (2 seconds)

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  //////////////////////////////////////////////////////////////////////////////
  /////////////////
  /// Nav to ThreadUnic
  const navigate = useNavigate();
  const [threadId, setThreadId] = useState("");
  const [currentThreadId, setCurrentThreadId] = useState(null);

  const startNewConversation = async (
    atecoL3,
    regione,
    assistant,
    incentiveId,
    firstName,
    companyName,
    companySize,
    companyEmployees,
    companyTurnover
  ) => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const userMessage = `Ciao, e questo incentivo buona per nostra azienda? Io sono ${firstName} e l'azienda si chiama ${companyName}. Siamo in ${regione} con ateco: ${atecoL3}. Siamo un ${companySize} con ${companyEmployees} dipendenti e turnover annuale di €${companyTurnover}.`;
      const response = await fetch(`${API_BASE_URL}/api/start_new_convo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: userMessage }),
      });
      //console.log("API Base URL:", API_BASE_URL);
      //console.log("firstName:", firstName);
      //console.log("companyName:", companyName);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      //console.log("Response data:", data); // Log the entire response data
      localStorage.setItem("currentThreadId", data.thread_id);
      //console.log("Stored thread_id in localStorage:", data.thread_id);

      if (data.thread_id && assistant) {
        //console.log("New thread ID:", data.thread_id, "assistant:", assistant);
        const title = `Discussion on ATECO ${atecoL3} in ${regione}`;
        // Navigate to the threadUnic page with the thread ID and assistant ID in the URL
        navigate(`/threadUnic/${assistant}/${data.thread_id}/${incentiveId}`, {
          state: { title: title },
        });
      } else {
        console.error(
          "Thread ID or Assistant ID was not returned from the server."
        );
      }
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="searchy-container">
      {!isAuthenticated && <UserPromptModal />}

      <div style={{ padding: "20px" }}>
        {document ? (
          <div className="news-article-unic">
            <p
              style={{ fontWeight: "bold" }}
              className="incentivethread-subdetails-unicid3"
            >
              {document.Titolo}
            </p>
            <img
              className="image-article-unicid"
              src={document.urlImage}
              alt={document.Titolo}
            />
            <div className="incentivethread-subdetails-unicid2">
              <p>
                <strong>Apertura:</strong> {formatDate(document.Data_apertura)}
              </p>
              <p>
                <strong>Chiusura:</strong> {formatDate(document.Data_chiusura)}
              </p>
              <p style={{ fontWeight: "bold" }}>{document.KeyBenefit}</p>
              <p style={{ fontWeight: "bold" }}>
                {formatDescription(document.Summary)}
              </p>
            </div>
          </div>
        ) : (
          <p>No data available.</p>
        )}

        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <div className="searchy-container">
      <div>
        <div >
          <p style={{ fontWeight: 'bold', color: "white" }} >
            Procedi
          </p>
          {/* Arrow Button for Redirect */}
          <button
            className="button-stylish"
            onClick={() =>
              startNewConversation(
                atecoL3,
                regione,
                assistant, // Replace with actual assistant ID if needed
                incentiveId, // Replace with actual incentive ID if needed
                firstName,
                companyName,
                companySize,
                companyEmployees,
                companyTurnover
              )
            }
            style={{
              cursor: 'pointer',
              fontSize: '48px', // Ensure the arrow is large enough
              background: 'transparent', // Transparent background
              color: 'white', // Color for the arrow
              display: 'flex', // Align button properly
            }}
          >
            →
          </button>
        </div>
      </div>
    </div>
        {/* Mapping Incentives */}
        {incentives.length > 0 ? (
          <div
            className="incentivy-container"
            style={{
              background: "#092f7a",
              padding: "20px",
              borderRadius: "8px",
              marginTop: "20px",
            }}
          >
            <ul style={{ padding: "10px", listStyleType: "none" }}>
              {incentives.map((doc, index) => (
                <li
                  key={index}
                  style={{
                    marginBottom: "20px",
                    border: "1px solid #ccc",
                    padding: "20px",
                    borderRadius: "8px",
                    boxShadow: "0 4px 6px rgba(0,0,0,0.1), 5px 5px 5px #FF5758",
                    backgroundColor: "#fff",
                    lineHeight: "2rem",
                    fontSize: "20px",
                    position: "relative",
                  }}
                >
                  <h2 style={{ marginTop: "20px" }}>{doc.Titolo}</h2>
                  <strong>{doc.KeyBenefit} <br /></strong>
                  <strong>Apertura:</strong> {formatDate(doc.Data_apertura)}{" "}
                  <strong>Chiusura:</strong> {formatDate(doc.Data_chiusura)}
                  <br />

                  <div>
                    {doc.Obiettivo_Finalita.map((goal) => (
                      <span
                        key={goal}
                        className={`category-box ${getCategoryClass(goal)}`}
                      >
                        {goal}
                      </span>
                    ))}
                  </div>

                  <pre className="description-box">
                    {formatDescription(doc.Summary)}
                  </pre>

                  {/* Right arrow button for each incentive */}
                  <button
                    className="button-stylish"
                    onClick={() => {
                      const assistant = doc.AssistantID; // Extract assistant from doc
                      startNewConversation(
                        atecoL3,
                        regione,
                        assistant,
                        doc.ID_Incentivo,
                        firstName,
                        companyName,
                        companySize,
                        companyEmployees,
                        companyTurnover
                      );
                    }}
                    style={{
                      cursor: 'pointer',
                      fontSize: '24px', // Ensure the arrow is large enough
                      border: '2px solid white', // White border
                      background: '#f8f9fa', // Light background color for contrast
                      color: '#000', // Color for the arrow
                      position: 'absolute', // Position the arrow
                      top: '10px', // Align to top right
                      right: '10px',
                      borderRadius: '4px', // Rounded corners
                      padding: '5px 10px', // Padding for button content
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow background
                    }}
                  >
                    →
                  </button>
                </li>
              ))}
            </ul>
          </div>
       ) : null} 
        
      </div>
    </div>
  );
}
export default IncentiveUnic;
