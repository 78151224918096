///imports
import React, {
  useEffect,
  useState,
} from "react";
import { db, auth } from "..//config/firebase.js";
import {
  getDocs,
  collection,
  orderBy,
  query,
  limit,
} from "firebase/firestore";
import "..//App.css";


function OldNews() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      const newsCollectionRef = collection(db, "news");
      const q = query(
        newsCollectionRef,
        orderBy("timestamp", "desc"),
        limit(30)
      );
      const querySnapshot = await getDocs(q);
      const fetchedNews = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNews(fetchedNews);
    };

    fetchNews();
  }, []);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';  
    const date = new Date(timestamp.seconds * 1000); 
    return date.toLocaleDateString();  
  };
  
  
  return (
    <div className="news-container">
    <div className="oldnews-container">
    <div style={{ position: "relative", textAlign: "left", border: "none" }}>
      <div className="notizia">NOTIZIA PASSATA </div>

      <div className="news-container">
        {news.length > 0 ? (
          news.map((article, index) => (
            <div key={index} className="oldnews-article">
              <p style={{color: "white", display: "flex", margin: "1px"}}>{formatDate(article.timestamp)}</p>
              <h2 className="article-title">{article.title}</h2>
              <p className="article-summary">{article.summary}</p>
            </div>
          ))
        ) : (
          <p>Loading news...</p>
        )}
      </div>
    </div>
    </div>
    </div>
  );
}

export default OldNews;
