import React, { useState } from 'react';

function RatingComponent({ onSubmitRating }) {
  const [selectedRating, setSelectedRating] = useState(0);
  const [showThanks, setShowThanks] = useState(false);

  const handleRating = async (rating) => {
    setSelectedRating(rating);  // Update local state with the new rating
    try {
      await onSubmitRating(rating); 
      setShowThanks(true);
      setTimeout(() => {
        setSelectedRating(0);
        setShowThanks(false);
      }, 2000);
    } catch (error) {
      console.error("Failed to submit rating:", error);
      // Optionally handle error, e.g., notify the user
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',  // Change to column to stack children vertically
      alignItems: 'center',  // Center children horizontally
      maxWidth: '200px',
      margin: '20px',
      position: 'relative'
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',  // Space out the rating circles
        width: '100%'  // Take full width of the container
      }}>
        {[1, 2, 3, 4, 5].map((rating) => (
          <div
            key={rating}
            style={{
              width: '30px',
              height: '30px',
              backgroundColor: selectedRating >= rating ? '#FF5758' : '#E0E0E0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              borderRadius: '50%'
            }}
            onClick={() => handleRating(rating)}
          >
            {rating}
          </div>
        ))}
      </div>
      <div style={{ marginTop: '10px', fontSize: '14px', color: '#FF5758' }}>
        Com'è stata la risposta?
      </div>
      {showThanks && <span style={{ position: 'absolute', right: '-120px', top: '5px', fontWeight: 'bold' }}>Grazie!</span>}
    </div>
  );
}

export default RatingComponent;
