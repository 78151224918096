import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';
import { auth, db } from '../config/firebase.js'; // Adjust this import based on the actual path to your Firebase configuration
import { Navigate } from 'react-router-dom';

// Context for authentication state
const AuthContext = createContext();

// Hook for easy context consumption
export const useAuth = () => useContext(AuthContext);

// Provider component to wrap your app and provide auth state
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        //console.log("User is signed in:", user);
        setCurrentUser(user);

        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const role = userDoc.data().role;
            //console.log("User role fetched from Firestore:", role);
            setUserRole(role);
          } else {
            //console.log("No such document!");
          }
        } catch (error) {
          //console.error("Error fetching user role:", error);
        }
      } else {
        //console.log("User is signed out");
        setCurrentUser(null);
        setUserRole(null);
      }
    });

    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  const value = { currentUser, userRole, setUserRole };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Route guards
export const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/login" />;
};

export const UserRoute = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/campusFeedback" />;
};

export const UserRouted = ({ children }) => {
  const { currentUser } = useAuth();
  // Adjust the redirect path as necessary
  return currentUser ? children : <Navigate to="/threadUnic/:threadId" />;
};
