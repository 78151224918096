//imports
import React, { useEffect, useState, useRef } from "react";
import { db, auth } from "..//config/firebase.js";
import "..//../src/App.css";
import {
  getDocs,
  collection,
  orderBy,
  where,
  query,
} from "firebase/firestore";
import UserPromptModal from "..//../src/components/userPrompt.js";
import ThreadUnic from "..//pages/threadUnic.js";
import {
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";

function OldIncentives() {
  const headerStyles = {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    padding: "1px",
    fontFamily: "'Poppins', sans-serif",
    textAlign: "center",
    wordSpacing: "6px",
    fontSize: "35px",
    //animation: "flash 3s infinite",
  };

  ////////////////////////////////////////////////////////////////////////////////////
  //text entry to fb db
  const [newQ1, setNewQ1] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const questionsCollectionRef = collection(db, "questions");
  const threadsCollectionRef = collection(db, "threads");
  const [lastVisible, setLastVisible] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.currentUser) {
      getEnrichedThreads();
    } else {
      console.log("Waiting for user authentication...");
    }
  }, [auth.currentUser]); // Or simply []

  const getEnrichedThreads = async () => {
    try {
      const userId = auth.currentUser ? auth.currentUser.uid : null;
      if (!userId) {
        console.error("User is not authenticated.");
        return;
      }

      // Fetch all threads and map them by threadId
      const threadTitles = {};
      const threadsSnapshot = await getDocs(threadsCollectionRef);
      threadsSnapshot.forEach((doc) => {
        const threadData = doc.data();
        threadTitles[threadData.threadId] = threadData.title;
      });

      // Fetch questions and enrich them with titles
      const q = query(
        questionsCollectionRef,
        where("userId", "==", userId),
        orderBy("timestamp", "desc")
      );
      const querySnapshot = await getDocs(q);

      let threadsArray = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        title: threadTitles[doc.data().threadId] || "No Title",
      }));

      // Deduplicate threads based on threadId, maintaining the latest entry (assuming latest by timestamp)
      const uniqueThreads = {};
      threadsArray.forEach((thread) => {
        // assuming threadId is always available
        if (
          !uniqueThreads[thread.threadId] ||
          uniqueThreads[thread.threadId].timestamp.seconds <
            thread.timestamp.seconds
        ) {
          uniqueThreads[thread.threadId] = thread;
        }
      });

      // Convert the uniqueThreads object back to an array
      setThreads(Object.values(uniqueThreads));
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    getEnrichedThreads();
  }, []); // Dependency array is empty, so this runs once on component mount

  const [showUserPrompt, setShowUserPrompt] = useState(false);
  useEffect(() => {
    const userHasVisited = localStorage.getItem("userHasVisited");
    if (!userHasVisited) {
      setShowUserPrompt(true);
    }
  }, []);
  const handleClose = () => {
    localStorage.setItem("userHasVisited", "true");
    setShowUserPrompt(false);
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////

  const [question, setQuestion] = useState("");
  const [docId, setDocId] = useState("");
  const [threadId, setThreadId] = useState("");
  const [apiResponse, setApiResponse] = useState("");
  const [messages, setMessages] = useState([
    {
      role: "system",
      content:
        "You are a helpful assistant with good general knowledge for checking facts",
      file_ids: "123",
    },
  ]);

  const [currentThreadId, setCurrentThreadId] = useState(null);

  const filteredQuestions = questionList.filter((question) =>
    currentThreadId ? question.threadId === currentThreadId : true
  );

  const [threads, setThreads] = useState([]);

  useEffect(() => {
    const fetchThreads = async () => {
      const threadsSnapshot = await getDocs(threadsCollectionRef);

      let threadsArray = threadsSnapshot.docs.map((doc) => ({
        id: doc.id,
        threadId: doc.data().threadId,
        title: doc.data().title,
        timestamp: doc.data().timestamp,
        assistant: doc.data().assistant,
      }));

      // Sort threadsArray by timestamp in descending order
      threadsArray.sort((a, b) => {
        const timestampA = a.timestamp ? a.timestamp.seconds : 0;
        const timestampB = b.timestamp ? b.timestamp.seconds : 0;
        return timestampB - timestampA;
      });

      // Filter for unique threadIds
      const uniqueThreads = threadsArray.reduce((acc, current) => {
        const x = acc.find((item) => item.threadId === current.threadId);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      setThreads(uniqueThreads);
    };

    fetchThreads();
  }, []);

  const handleThreadUnic = (threadId, assistant, incentiveId) => {
    navigate(`/threadUnic/${assistant}/${threadId}/${incentiveId}`);
  };

  return (
    <div style={{ position: "relative", textAlign: "center", border: "none" }}>
      <div>
        {!isAuthenticated && <UserPromptModal />}
        <div className="notizia"> INCENTIVI PRECEDENTI</div>
        <div className="news-article">
          {/* <div className="newchat">
            Avvia una nuova ricerca di incentivi, scopri se sei idoneo e come
            candidarti
          </div> */}

         {/*  <div className="old-title">
            <div>Iniziamo per cercare per un incentivo</div>
            <button
              style={{
                color: "#285075",
                fontSize: "26px",
                cursor: "pointer",
                background: "white",
                borderRadius: "5px",
                border: "2px solid #285075",
                padding: "10px 20px", // More comfortable touch area
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)", // Adds shadow for depth
                transition: "all 0.3s ease", // Smooths the transition of style changes
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.background = "#f0f0f0"; // Light grey background on hover
                e.currentTarget.style.color = "#204060"; // Slightly darker text on hover
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.background = "white"; // Revert to original on mouse out
                e.currentTarget.style.color = "#285075"; // Revert text color
              }}
            >
              &#10145;{" "}
            </button>
          </div> */}

         {/*  <div className="newchat">Oppure continua le ultime sessioni</div> */}

          {isAuthenticated ? (
            <div className="table-frontpage">
              <table>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Ultimo Messaggio</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {threads.map((thread) => (
                    <tr
                      key={thread.id}
                      onClick={() => handleThreadUnic(thread.threadId)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{thread.title || "No Title"}</td>
                      <td>
                        {thread.q1
                          ? `${thread.q1.substring(0, 50)}${
                              thread.q1.length > 50 ? "..." : ""
                            }`
                          : "No content"}
                      </td>
                      <td>
                        {thread.timestamp
                          ? new Date(
                              thread.timestamp.seconds * 1000
                            ).toLocaleDateString("en-UK", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                            })
                          : "No timestamp"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="login-prompt">
              Please log in to view your messages.
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="full-display">
          <div className="questions-display">
            {filteredQuestions.map((question, index) => {
              // Convert timestamp to JavaScript Date object
              const questionDate = question.timestamp?.toDate();

              // Format the date as a simple string for comparison (ignoring time part)
              const questionDateString = questionDate?.toLocaleDateString();

              // Check if this is the first question of a new day
              const isFirstQuestionOfDay =
                index === 0 ||
                filteredQuestions[index - 1]?.timestamp
                  ?.toDate()
                  ?.toLocaleDateString() !== questionDateString;

              return (
                <div key={question.id} className="question-item">
                  {/* Display the timestamp if it's the first question of the day */}
                  {isFirstQuestionOfDay && (
                    <div className="date-display">
                      <small>{questionDateString}</small>
                    </div>
                  )}

                  {/* Display the question with "User: " prefix */}
                  <div className="question-text">
                    <p>
                      <strong>User:</strong> {question.q1}
                    </p>
                  </div>

                  {/* Display the Thread ID */}
                  <div className="question-text">
                    <p>
                      <strong>Thread ID:</strong> {question.threadId}
                    </p>
                  </div>

                  {/* Display the response */}
                  <div className="generic-response">
                    <p>
                      <strong>Agent:</strong>
                      {index === filteredQuestions.length - 1
                        ? apiResponse || "Waiting for response..."
                        : question.response}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div>
        <div className="input-container"></div>
      </div>
      <div className="margin-landing">
        <div
          style={{
            background: "#092f7a",
            display: "flex",
            justifyContent: "space-evenly",
            background: "white",
          }}
        >
          <button type="button" className="login-button">
            Login | User Portal{" "}
          </button>
          <button type="button" className="login-button">
            SignUp{" "}
          </button>
          <button type="button" className="login-button">
            Q&A{" "}
          </button>
          <button type="button" className="login-button">
            Privacy Policy{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default OldIncentives;
