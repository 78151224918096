import React, { useEffect, useState } from "react";
import "./home.css";
import { db } from "../config/firebase.js";
import { getDocs, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

export const incentiviCollectionRef = collection(db, "incentivi");

function Home() {
  const [incentiviList, setIncentiviList] = useState([]);
  const navigate = useNavigate();

  const getIncentiviList = async () => {
    try {
      const data = await getDocs(incentiviCollectionRef);
      setIncentiviList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    getIncentiviList();
  }, []); // Dependency array is empty, so this effect will only run once

  const handleLogoClick = () => {
    navigate("/mapview");
  };

  return (
    <div className="home-styles">
      <div>
        <div className="text-box-above-header"></div>
        <div className="container1" style={{ position: "sticky", top: "50px", zindex: "100" }}>
          <button type="button" onClick={() => navigate("/home")} className="offer-button">Incentivi</button>
          <button type="button" onClick={() => navigate("/campus")} className="offer-button">Decreti</button>
          <button type="button" onClick={() => navigate("/doposcuola")} className="offer-button">Chi Siamo</button>
        </div>

      <div style = {{marginLeft: "50px",marginRight: "50px", color: "white" }}>
        {incentiviList.map((incentivi) => (
          <div key={incentivi.id} className="incentivi-container-home bg-black font-sans">
            <div className="incentivi-column-home incentivi-type"></div>
            <div className="incentivi-column-home incentivi-city-home bg-black">
              <p className="incentivi-info-home"> ID: {incentivi.ID_Incentivo}</p>
            </div>
            <div className="incentivi-column-home incentivi-city-home bg-black">
              <p className="incentivi-info-home">Titolo: {incentivi.Titolo}</p>
            </div>
            <div className="incentivi-column-home">
              <p className="incentivi-info-home">Object: {incentivi.Obiettivo_Finalita}</p>
            </div>
            <div className="incentivi-column-home">
              <p className="incentivi-info-home">Regioni: {incentivi.Regioni}</p>

            </div>
          </div>
        ))}
        </div>
      </div>
      <div className="ender"></div>
    </div>
  );
}

export default Home;
