import React, { createContext, useState, useContext } from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);

    const addToCart = (item) => {
        /* console.log("Adding item to cart:", item); */
        setCartItems(prevItems => {
            const updatedItems = prevItems.find(prevItem => prevItem.id === item.id)
                ? prevItems.map(prevItem =>
                    prevItem.id === item.id ? { ...prevItem, quantity: prevItem.quantity + 1 } : prevItem
                  )
                : [...prevItems, { ...item, quantity: 1 }];
    
            // Update the local storage with the updated items
            localStorage.setItem('cartItems', JSON.stringify(updatedItems));
    
            return updatedItems;
        });
    };

    const updateQuantity = (itemId, newQuantity) => {
        setCartItems(currentItems =>
            currentItems.map(item =>
                item.id === itemId ? { ...item, quantity: newQuantity } : item
            )
        );
    };

    const removeFromCart = (itemId) => {
        setCartItems(prevItems => prevItems.filter(item => item.id !== itemId));
    };

    const clearCart = () => {
        setCartItems([]);
        localStorage.removeItem('cartItems');
    };

    // The cartCount can be derived directly from cartItems
    const cartCount = cartItems.reduce((count, item) => count + item.quantity, 0);

    const contextValue = {
        cartItems,
        addToCart,
        removeFromCart,
        updateQuantity,
        clearCart,
        cartCount // You can still provide cartCount for convenience
    };

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
};
