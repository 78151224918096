//// this is the front page
// export default Navbar;
import "./index.css";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useNavigate, Navigate } from "react-router-dom";
//import { analytics } from "../../config/firebase.js";
//import pluto from '..//../pluto3.png';
import { useCart } from "..//../pages/cartContext.js";
import { useAuth } from "../auth-email.js";
import { doc, getDoc } from "firebase/firestore";
import landingPic from "..//../noun-pyramid-6037917.png";
import logo from "..//../IncentivatoLogo4.png";
import { FaUserCircle } from 'react-icons/fa';
import { auth, googleProvider } from "..//../config/firebase.js";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";

const Nav = styled.nav`
  background-color: #092f7a;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: left;
  position: sticky;
  z-index: 100;
  top: 0;
`;

const NavMenu = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
  margin-left: 10px;
  display: none; /* Initially hidden */
  margin-bottom: 30px;

  &.menu-open {
    display: block; /* Show menu */
  }
`;


const NavItem = styled.li`
  margin-bottom: 3px;
`;

const MenuToggle = styled.div.attrs(({ isOpen }) => ({
  style: {
    display: isOpen ? "none" : "flex",
  }
}))`
  flex-direction: column;
  cursor: pointer;
  align-items: left;
  justify-content: left;
`;



const Bar = styled.div`
  width: 23px;
  height: 3px;
  background-color: white;
  margin: 3px 0;
  justify-content: left;
`;

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { cartCount } = useCart();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const handleNavigateToCart = () => navigate("/cart");
  const { currentUser } = useAuth();

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => setShowPopup(!showPopup);

  const logout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (err) {
      console.error("Error logging out:", err);
    }
  };
  

  return (
    <Nav
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
       <div className="userLogin">
            {currentUser ? (
                <FaUserCircle onClick={togglePopup} style={{ cursor: 'pointer', fontSize: '24px' }} />
            ) : (
                <span>Please log in</span>
            )}
            {showPopup && currentUser && (
                <div className="popup">
                    <div className="popup-inner">
                        <button onClick={togglePopup}>X</button>
                        <p>Sei stato registrato: {currentUser.email}</p>
                        <button
            type="button"
            onClick={logout}
            className = "button-style"
          >
            Logout{" "}
          </button>
                    </div>
                </div>
            )}
        </div>
      <div className="navContainer">
        <button onClick={() => navigate("/")} className="headerStyles">
          <div style={{alignItems: "center" }}>
            {" "}
            {/* Changes for vertical alignment */}
            <img
              src={logo}
              alt="Incentivato Logo"
              className="brand-logo-fp"
            />
           {/*  <div style={{ display: "flex", alignItems: "center", paddingLeft: "2px" }}>
              {" "}
              CAPITAL AI
            </div> */}
            {/*  <div className="sloganStyles">
                SMART <span className="letterSpacing"></span>F<span className="letterSpacing"></span>INANCE
            </div>
            <div className="sloganStyles">
                MICRO <span className="letterSpacing"></span>I<span className="letterSpacing"></span>MPRESE
            </div> */}
          </div>
        </button>
        <div className="menuToggleContainer" onClick={toggleMenu}>
          <MenuToggle isOpen={isMenuOpen}>
            <Bar></Bar>
            <Bar></Bar>
            <Bar></Bar>
          </MenuToggle>
        </div>

        {/* Desktop Nav Items directly under the slogan */}
        <div className="navMenu">
          <div className="navItem" onClick={() => navigate("/search")}>
            Cercare Incentivi
          </div>
          <div className="navItem" onClick={() => navigate("/oldincentives")}>
            Riprendere Incentivi
          </div>
          <div className="navItem" onClick={() => navigate("/service-offers")}>
            Comprare Servizi
          </div>
          <div className="navItem" onClick={() => navigate("/oldnews")}>
            Cercare Notizia
          </div>
          <div className="navItem" onClick={() => navigate("/login")}>
            Login
          </div>
        </div>
      </div>

      {/* Mobile NavMenu, controlled by isMenuOpen */}
      <NavMenu className={`menu-font-size ${isMenuOpen ? 'menu-open' : ''}`}>
        <NavItem onClick={() => setIsMenuOpen(false)}>
          <NavLink to="/search" style={{ color: "white" }}>Cercare Incentivi</NavLink>
        </NavItem>
        <NavItem onClick={() => setIsMenuOpen(false)}>
          <NavLink to="/oldincentives" style={{ color: "white" }}>Riprendere Incentivi</NavLink>
        </NavItem>
        <NavItem onClick={() => setIsMenuOpen(false)}>
          <NavLink to="/service-offers" style={{ color: "white" }}>Comprare Servizi</NavLink>
        </NavItem>
        <NavItem onClick={() => setIsMenuOpen(false)}>
          <NavLink to="/oldnews" style={{ color: "white" }}>Cercare Notizia</NavLink>
        </NavItem>
        <NavItem onClick={() => setIsMenuOpen(false)}>
          <NavLink to="/login" style={{ color: "white" }}>Log In</NavLink>
        </NavItem>
      </NavMenu>
    </Nav>
  );
};

export default Navbar;

{
  /* <div 
          style={{ top: "15px", left: "30px" }} 
          className="cart-icon-container"
          onClick={handleNavigateToCart}
        >
          <i className="fa fa-shopping-cart"></i>
          {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
        </div> */
}
