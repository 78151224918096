///imports
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { db, auth } from "./config/firebase.js";
import {
  getDocs,
  collection,
  addDoc,
  serverTimestamp,
  orderBy,
  where,
  query,
  doc,
  updateDoc,
  limit,
  startAfter,
  onSnapshot,
} from "firebase/firestore";
import Navbar from "./components/Navbar/index.js";
import Home from "./pages/home.js";
import Initmaps from "./pages/campusFeedback.js";
import SignUp from "./pages/signup.js";
import Contact from "./pages/contact.js";
import DisclaimerCampus from "./pages/disclaimer-campus.js";
import LogIn from "./pages/login.js";
//import Campus from "./pages/campus.js";
import Checkout from "./pages/checkout.js";
import Cart from "./pages/cart.js";
import PaymentSuccess from "./pages/paymentSuccess.js";
import Users from "./pages/users.js";
import Layout from "./Layout.js";
import IncentiveSearch from "./pages/agent2.js";
import IncentiveSearchCommercialista from "./pages/agent2Commercialista.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import "./App.css";
import DisclaimerModal from "./DisclaimerModal.js";
import { CartProvider } from "./pages/cartContext.js";
import UserPromptModal from "..//src/components/userPrompt.js";
import ThreadUnic from "./pages/threadUnic.js";
import Preapply from "./pages/preApply.js";
import IncentiveUnic from "./pages/incentiveUnic.js";
import Survey from "./pages/survey.js";
import WebBot from "./pages/webBot.js";
import OldNews from "./pages/oldnews.js";
import ServiceOffers from "./pages/serviceOffers.js";
import OldIncentives from "./pages/oldincentives.js";
import { useParams } from "react-router-dom";
//import landingPic from './landing-pic2.png';
import landingPic from "../src/noun-pyramid-6037917.png";
import credsPic from "../src/noun-apply-job-6223684.png";
import qualifyPic from "../src/noun-award-6182518.png";
import searchPic from "../src/noun-search-6811990.png";
import applyPic from "../src/noun-apply-1903010.png";
import {
  AuthProvider,
  useAuth,
  PrivateRoute,
  UserRoute,
} from "./components/auth-email.js";
import StageIndicator from "..//src/components/stages.js";

function App() {
  const headerStyles = {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    padding: "1px",
    fontFamily: "'Poppins', sans-serif",
    textAlign: "center",
    wordSpacing: "6px",
    fontSize: "35px",
    //animation: "flash 3s infinite",
  };

  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  useEffect(() => {
    const isDisclaimerAccepted =
      localStorage.getItem("disclaimerAccepted") === "true";
    setDisclaimerAccepted(isDisclaimerAccepted);
  }, []);

  const handleAcceptDisclaimer = () => {
    localStorage.setItem("disclaimerAccepted", "true");
    setDisclaimerAccepted(true);
  };

  const SearchPage = () => (
    <div>
      <StageIndicator currentStage={1} />
      {/* Content of Search Page */}
    </div>
  );

  const QualifyPage = () => (
    <div>
      <StageIndicator currentStage={2} />
      {/* Content of Qualify Page */}
    </div>
  );

  const ApplyPage = () => (
    <div>
      <StageIndicator currentStage={3} />
      {/* Content of Apply Page */}
    </div>
  );

  const SearchRoute = () => {
    const { userRole } = useAuth();

    return (
      <>
        <StageIndicator currentStage={1} />
        {userRole === 'commercialista' ? <IncentiveSearchCommercialista /> : <IncentiveSearch />}
      </>
    );
  };

  return (
    <CartProvider>
      <div>
        <Router>
          <AuthProvider>
            {!disclaimerAccepted && (
              <DisclaimerModal onAccept={handleAcceptDisclaimer} />
            )}
            <Navbar />{" "}
            {/* Render the Navbar outside of RouteContentWithImage */}
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <RouteContentWithImage>
                    <Layout />
                  </RouteContentWithImage>
                }
              />
              <Route path="/home" element={<Home />} />
              <Route path="/create" element={<PrivateRoute></PrivateRoute>} />
              <Route path="/service-offers" element={<ServiceOffers />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/disclaimer-campus" element={<DisclaimerCampus />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<LogIn />} />
              <Route path="/oldIncentives" element={<OldIncentives />} />
              <Route path="/oldnews" element={<OldNews />} />
              {/* <Route
                path="/search"
                element={
                  <>
                    <StageIndicator currentStage={1} />
                    <IncentiveSearch />
                  </>
                }
              /> */}
              <Route
                path="/search"
                element={<SearchRoute />}
              />
              {/* <Route path="/threadUnic/:threadId" element={<ThreadUnic />} /> */}
              <Route
                path="/threadUnic/:assistant/:threadId/:incentiveId"
                element={
                  <>
                    <StageIndicator currentStage={2} />
                    <ThreadUnic />
                  </>
                }
              />
              <Route
                path="/threadUnic/:assistant/:threadId/:incentiveId/Apply"
                element={
                  <>
                    <StageIndicator currentStage={3} />
                    <Preapply />
                  </>
                }
              />
              <Route
                path="/:assistant/:incentiveId"
                element={
                  <>
                    <IncentiveUnic />
                  </>
                }
              />
              <Route
                path="/survey/:assistant/:threadId/"
                element={
                  <>
                    <Survey />
                  </>
                }
              />
              <Route
                path="/webBot/:assistant/:threadId/"
                element={
                  <>
                    <WebBot />
                  </>
                }
              />
              <Route
                path="/webBot/:assistant/"
                element={<WebBot />}
  />
              <Route
                path="/campusFeedback"
                element={
                  <UserRoute>
                    {" "}
                    <Initmaps />{" "}
                  </UserRoute>
                }
              />
              <Route path="/cart" element={<Cart />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/userlogin" element={<Users />} />
              <Route
                path="/paymentSuccess/:docId/:paymentIntentId"
                element={<PaymentSuccess />}
              />
              {/* <Route path="/return" element={<Return />} /> */}
              {/* <Route path="/ordercomplete" element={<Return />} /> */}
            </Routes>
          </AuthProvider>
        </Router>
        {/* <div className="email">Email: info@capital-ai.eu </div> */}
        <div className="sources">Sources: OpenData (incentivi.gov.it) </div>
      </div>
    </CartProvider>
  );
}

// Custom component for conditionally rendering content with an image
function RouteContentWithImage() {
  // Get the current route pathname
  const pathname = window.location.pathname;

  // Conditionally render the image
  const renderImage = pathname === "/";

  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);

  ////////////////////////////////////////////////////////////////////////////////////
  //text entry to fb db
  const [newQ1, setNewQ1] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const questionsCollectionRef = collection(db, "questions");
  const threadsCollectionRef = collection(db, "threads");
  const newsCollectionRef = collection(db, "news");
  const [lastVisible, setLastVisible] = useState(null);

  useEffect(() => {
    if (auth.currentUser) {
      getEnrichedThreads();
    } else {
      //console.log("Waiting for user authentication...");
    }
  }, [auth.currentUser]); // Or simply []

  const getEnrichedThreads = async () => {
    try {
      const userId = auth.currentUser ? auth.currentUser.uid : null;
      if (!userId) {
        //console.error("User is not authenticated.");
        return;
      }

      // Fetch all threads and map them by threadId
      const threadTitles = {};
      const threadsSnapshot = await getDocs(threadsCollectionRef);
      threadsSnapshot.forEach((doc) => {
        const threadData = doc.data();
        threadTitles[threadData.threadId] = threadData.title;
      });

      // Fetch questions and enrich them with titles
      const q = query(
        questionsCollectionRef,
        where("userId", "==", userId),
        orderBy("timestamp", "desc")
      );
      const querySnapshot = await getDocs(q);

      let threadsArray = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        title: threadTitles[doc.data().threadId] || "No Title",
      }));

      // Deduplicate threads based on threadId, maintaining the latest entry (assuming latest by timestamp)
      const uniqueThreads = {};
      threadsArray.forEach((thread) => {
        // assuming threadId is always available
        if (
          !uniqueThreads[thread.threadId] ||
          uniqueThreads[thread.threadId].timestamp.seconds <
            thread.timestamp.seconds
        ) {
          uniqueThreads[thread.threadId] = thread;
        }
      });

      // Convert the uniqueThreads object back to an array
      setThreads(Object.values(uniqueThreads));
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    getEnrichedThreads();
  }, []); // Dependency array is empty, so this runs once on component mount

  const [showUserPrompt, setShowUserPrompt] = useState(false);
  useEffect(() => {
    const userHasVisited = localStorage.getItem("userHasVisited");
    if (!userHasVisited) {
      setShowUserPrompt(true);
    }
  }, []);
  const handleClose = () => {
    localStorage.setItem("userHasVisited", "true");
    setShowUserPrompt(false);
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////

  const [question, setQuestion] = useState("");
  const [docId, setDocId] = useState("");
  const [threadId, setThreadId] = useState("");
 //const [jsonData, setJsonData] = useState(Opendata); // Ensure Opendata is defined
  const [apiResponse, setApiResponse] = useState("");
  const [messages, setMessages] = useState([
    {
      role: "system",
      content:
        "You are a helpful assistant with good general knowledge for checking facts",
      file_ids: "123",
    },
  ]);

  const [currentThreadId, setCurrentThreadId] = useState(null);

  const filteredQuestions = questionList.filter((question) =>
    currentThreadId ? question.threadId === currentThreadId : true
  );

  const [threads, setThreads] = useState([]);

  useEffect(() => {
    const fetchThreads = async () => {
      const threadsSnapshot = await getDocs(threadsCollectionRef);

      let threadsArray = threadsSnapshot.docs.map((doc) => ({
        id: doc.id,
        threadId: doc.data().threadId,
        title: doc.data().title,
        timestamp: doc.data().timestamp,
      }));

      // Sort threadsArray by timestamp in descending order
      threadsArray.sort((a, b) => {
        const timestampA = a.timestamp ? a.timestamp.seconds : 0;
        const timestampB = b.timestamp ? b.timestamp.seconds : 0;
        return timestampB - timestampA;
      });

      // Filter for unique threadIds
      const uniqueThreads = threadsArray.reduce((acc, current) => {
        const x = acc.find((item) => item.threadId === current.threadId);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      setThreads(uniqueThreads);
    };

    fetchThreads();
  }, []);

  const [title, setTitle] = useState("");
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    console.log("Title updated:", event.target.value);
  };

  const handleThreadUnic = (threadId) => {
    navigate(`/threadUnic/${threadId}`);
  };

  const startNewConversation = async (
    assistant = "asst_EcSBNs1B1atKqgqVaHwvFtAb"
  ) => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const userMessage = `Ciao, mi piace fare questo indaggio`;
      const response = await fetch(`${API_BASE_URL}/api/start_new_convo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: userMessage }),
      });
      //console.log("API Base URL:", API_BASE_URL);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      //console.log("Response data:", data); // Log the entire response data
      localStorage.setItem("currentThreadId", data.thread_id);
      //console.log("Stored thread_id in localStorage:", data.thread_id);

      if (data.thread_id && assistant) {
        //console.log("New thread ID:", data.thread_id, "assistant:", assistant);
        
        // Navigate to the threadUnic page with the thread ID and assistant ID in the URL
        navigate(`/survey/${assistant}/${data.thread_id}`, {
          state: { title: title },
        });
      } else {
        console.error(
          "Thread ID or Assistant ID was not returned from the server."
        );
      }
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    }
  };

  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      const newsCollectionRef = collection(db, "news");
      const q = query(
        newsCollectionRef,
        orderBy("timestamp", "desc"),
        limit(3)
      );
      const querySnapshot = await getDocs(q);
      const fetchedNews = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNews(fetchedNews);
    };

    fetchNews();
  }, []);

  const handleLike = async (articleId, currentLikes) => {
    const articleRef = doc(db, "news", articleId);
    const newLikes =
      typeof currentLikes === "number" && !isNaN(currentLikes)
        ? currentLikes + 1
        : 1;
    await updateDoc(articleRef, {
      likes: newLikes,
    });

    // Update the state immediately for UI feedback
    setNews((prevNews) =>
      prevNews.map((article) => {
        if (article.id === articleId) {
          return { ...article, likes: newLikes };
        }
        return article;
      })
    );
  };

  const [targetRef, setTargetRef] = useState(null);

  const NewsSlideshow = ({ news }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      // Ensure there is news data before setting the interval
      if (news && news.length > 0) {
        const intervalId = setInterval(() => {
          setCurrentIndex((current) => (current + 1) % news.length);
        }, 8000); // Rotate news every 5 seconds

        return () => clearInterval(intervalId); // Clean up the interval on unmount
      }
    }, [news]); // Depend on 'news' to restart the effect when news updates

    if (!news || news.length === 0) {
      return <p>Loading news...</p>; // Handle case where news is not yet available
    }

    return (
      <div className="news-slideshow">
        {news.length > 0 ? (
          <div className="news-article">
            <h2 className="article-title">{news[currentIndex].title}</h2>
            <img
              src={news[currentIndex].image_url}
              className="image-article"
              alt={news[currentIndex].title}
            />
            <p className="article-summary">{news[currentIndex].summary}</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ paddingLeft: "5px", fontSize: "16px" }}>
                {news[currentIndex].likes}
              </span>
            </div>
          </div>
        ) : (
          <p>Loading news...</p>
        )}
      </div>
    );
  };

  return (
    <div style={{ position: "relative", textAlign: "center", border: "none" }}>
      {/* <Slideshow className="general-css text-loop" /> */}
      <div className="container-flex">
        <div className="landing-width">
          <div className="newconvo-title">
            <div className="sloganContainer">
              <div className="sloganStyles" style={{ order: 2 }}>
                SIMPLIFY <span className="letterSpacing"></span>FINANCE
              </div>
              <div className="sloganStyles" style={{ order: 1,color:"#FF5758" }}>
                PMI e <span className="letterSpacing"></span>PROFESSIONISTI.
              </div>
              <div className="captionStyles" style={{ order: 3 }}>
                Stare al passo con i cambiamenti e la complessità degli
                incentivi rappresenta una sfida per le imprese.
              </div>
              <div className="captionStyles" style={{ order: 4 }}>
                Le soluzioni di Capital AI aiutano le piccole e medie imprese a
                cercare, qualificarsi e richiedere gli incentivi più recenti in
                modo che possano concentrarsi sulla gestione della propria
                attività.
              </div>
            </div>
          </div>

          <div
            onClick={() => navigate("/search")}
            onMouseOver={(e) => {
              e.currentTarget.style.background = "#f0f0f0";
              e.currentTarget.style.color = "#204060";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.background = "#092f7a";
              e.currentTarget.style.color = "white";
            }}
            className="landing-cta"
          >
            <div>Prova Gratuitamente</div>
          </div>
          <div
            onClick={() =>
              startNewConversation(
                
              )
            }
            onMouseOver={(e) => {
              e.currentTarget.style.background = "#f0f0f0";
              e.currentTarget.style.color = "#204060";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.background = "#092f7a";
              e.currentTarget.style.color = "white";
            }}
            className="landing-cta-survey"
          >
            <div>Fornisci Feedback</div>
          </div>
        </div>

        <div className="side-content">
         {/*  <NewsSlideshow news={news} className="general-css text-loop" /> */}
           {/* <img 
              src={landingPic}
              alt="A"
              style={{
                width: "50%", // Adjust width as needed to match the size of the text
                height: "auto",
                margin: "1px 1px 1px 1px", // Adds horizontal space around the image
              }}
            /> */}
             <div className="video-container">
          <video className="video-responsive" loop autoPlay muted>
            <source src="Incentivato2112.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        </div>
      </div>

      {/* <div class="line-container">
  <div className="chat-area"></div>
  </div> */}
<div className="aiutare">Come aiutiamo le imprese? </div>
<div className="aiutare-fp-container">
  <div className="help-item" style={{ cursor: "pointer" }}>
    <p className="title-fonts">Democratizzazione dell'accesso agli incentivi</p>
    <p className="description-fonts">Rendiamo accessibili gli incentivi finanziari a tutte le PMI, eliminando complessità e barriere.</p>
  </div>
  <div className="help-item">
    <p className="title-fonts">Trasparenza nella Domanda</p>
    <p className="description-fonts">Garantiamo un processo di candidatura completamente trasparente, consentendo alle imprese di seguire il percorso dei propri incentivi.</p>
  </div>
  <div className="help-item" style={{ cursor: "pointer" }}>
    <p className="title-fonts">Nessuna Perdita di Tempo</p>
    <p className="description-fonts">Candidati solo per gli incentivi per cui sei idoneo, massimizzando così l'efficienza e l'efficacia.</p>
  </div>
  <div className="help-item" style={{ cursor: "pointer" }}>
    <p className="title-fonts">Costi Vantaggiosi rispetto ai Servizi di Consulenza Tradizionali</p>
    <p className="description-fonts">Forniamo un servizio di qualità superiore a un costo inferiore rispetto ai metodi di consulenza tradizionali.</p>
  </div>
</div>

      <div className="solutions">INCENTIVI STRUMENTALI </div>
      <div className="image-fp-container">
        <div className="image-item" style={{ cursor: "pointer" }}>
          <img src={searchPic} alt=" " />
          <p className="type-fonts"> SEARCH</p>
        </div>
        <div className="image-item">
          <img src={qualifyPic} alt=" " />
          <p className="type-fonts">QUALIFY</p>
        </div>
        <div className="image-item" style={{ cursor: "pointer" }}>
          <img src={credsPic} alt=" " />
          <p className="type-fonts"> IDENTIFY</p>
        </div>
        <div className="image-item" style={{ cursor: "pointer" }}>
          <img src={applyPic} alt=" " />
          <p className="type-fonts"> APPLY</p>
        </div>
      </div>
      <div style={{background:"black"}}>
      <div class="newsunic-container">
        <div className="notizia">INCENTIVI NOTIZIA </div>

        <div className="news-container">
          {news.length > 0 ? (
            news.map((article, index) => (
              <div key={index} className="news-article">
                <h2 className="article-title">{article.title}</h2>
                <img
                  src={article.image_url}
                  className="image-article"
                  alt="News"
                />
                <p className="article-summary">{article.summary}</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button
                    onClick={() => handleLike(article.id, article.likes)}
                    style={{
                      cursor: "pointer",
                      fontSize: "20px",
                      background: "black",
                      border: "none",
                    }}
                  >
                    👍
                  </button>
                  <span style={{ paddingLeft: "5px", fontSize: "16px", color: "white", background: "black" }}>
                    {" "}
                    {article.likes}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <p>Loading news...</p>
          )}
        </div>
        <div className="margin-landing">
          <div
            style={{
              background: "#092f7a",
              display: "flex",
              justifyContent: "space-evenly",
              background: "white",
            }}
          >
            <button
              type="button"
              onClick={() => navigate("/login")}
              className="login-button"
            >
              User Portal{" "}
            </button>
            <button
              type="button"
              onClick={() => navigate("/signup")}
              className="login-button"
            >
              SignUp{" "}
            </button>
            <button
              type="button"
              onClick={() => navigate("/contact")}
              className="login-button"
            >
              Q&A{" "}
            </button>
            <button
              type="button"
              onClick={() => navigate("/contact")}
              className="login-button"
            >
              Privacy Policy{" "}
            </button>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

const Slideshow = () => {
  const texts = [
    "Commercialista Chatbots: Riducendo la complessità e aiutandovi a risolvere le vostre domande di contabilità",
    "Aiutandovi a trovare rapidamente informazioni sulle iniziative governative",
    "Nuovo Incentivo per Edicole 31/3/24",
    "Bonus per Ristoranti e Gelateria: Marzo - Aprile",
  ];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the current text index and wrap around if necessary
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2500); // 10000 milliseconds (10 seconds)

    return () => {
      clearInterval(interval); // Cleanup the interval when the component unmounts
    };
  }, []);

  return (
    <div>
      {/* Display the current text */}
      <div className="text-loop">{texts[currentTextIndex]}</div>
    </div>
  );
};

export default App;
