import { auth, googleProvider } from "../config/firebase.js";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ".//login.css";


function LogIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const signIn = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate(from);
    } catch (err) {
      console.error(err);
      // Here we determine the type of error and set a friendly message
      if (err.code === 'auth/wrong-password') {
        setErrorMessage('The password you entered is incorrect. Please try again.');
      } else if (err.code === 'auth/user-not-found') {
        setErrorMessage('No account exists with this email. Please check your email or register.');
      } else {
        setErrorMessage('Failed to log in. Please try again later.');
      }
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate(from);
    } catch (err) {
      console.error(err);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (err) {
      console.error("Error logging out:", err);
    }
  };

  const resetPassword = async () => {
    if (email) {
      try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset email sent!");
      } catch (err) {
        console.error(err);
        alert("Failed to send password reset email.");
      }
    } else {
      alert("Please enter your email address.");
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#092f7a' }}>
    <div className="login-container">
      <div className="section">
        <h2>Create Account</h2>
        <button type="button" className="button" onClick={() => navigate("/signup")}>Sign Up - New User</button>
      </div>
      <div className="section">
        <h2>Login</h2>
        <form className="login-form" onSubmit={signIn}>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              placeholder="Email..."
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              placeholder="Password..."
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="button-group">
            <button type="submit">Sign In</button>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          </div>
        </form>
        <div className="button-group">
          <button
            type="button"
            onClick={signInWithGoogle}
            className="button-style"
          >
            Sign In With Google
          </button>
        </div>
      </div>
      <div className="horizontal-group">
        <button type="button" className="button-group" onClick={resetPassword}>Reset Password</button>
        <button type="button" className="button-group" onClick={logout}>Logout</button>
      </div>
      <footer>
        {/* <p>Email: info@capital-ai.eu</p> */}
        <p>Sources: OpenData (incentivi.gov.it)</p>
      </footer>
    </div>
    </div>
  );
}

export default LogIn;

