import React from 'react';
import '..//..//src/pages/allCSS.css'; 

const StageIndicator = ({ currentStage }) => {
    const stages = ['Search', 'Qualify', 'Apply'];

    return (
        <div className="stage-indicator">
            {stages.map((stage, index) => (
                <div className="stage-container" key={index}>
                    <div className={`circle ${currentStage > index + 1 ? 'completed' : currentStage === index + 1 ? 'active' : 'pending'}`}>
                        <div className={`tick ${currentStage > index + 1 ? 'tick-completed' : 'tick-pending'}`}>✓</div>
                    </div>
                    <div className="label">{stage}</div>
                </div>
            ))}
        </div>
    );
};

export default StageIndicator;