import React from "react";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { db } from "../config/firebase.js";
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { useCart } from "./cartContext.js";

export default function PaymentSuccess() {
  const [productDetails, setProductDetails] = useState({});
  const [orderDetails, setOrderDetails] = useState({});
  const [loading, setLoading] = useState(true);
  // Adjust useParams to include paymentIntentId
  const { paymentIntentId } = useParams();
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  const { clearCart } = useCart();

  useEffect(() => {
    const fetchProductDetails = async () => {
      // Retrieve cart items from local storage
      const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      //console.log("Cart items from localStorage:", storedCartItems);

      await delay(3000); // Wait for 3 seconds before fetching the details

      const details = await Promise.all(
        storedCartItems.map(async (item) => {
          try {
            const docRef = doc(db, "campus", item.id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              return { ...docSnap.data(), id: item.id, quantity: item.quantity };
            } else {
              //console.log("No such document with id:", item.id);
              return null;
            }
          } catch (error) {
            console.error("Error fetching product details:", error);
            return null;
          }
        })
      );

      setProductDetails(details.filter((item) => item !== null));
      setLoading(false);
    };

    fetchProductDetails();
   

    const fetchOrderDetails = async () => {
      // console.log(
      //   "Fetching order details for paymentIntentId:",
      //   paymentIntentId
      // );
      if (!paymentIntentId) {
        console.log("paymentIntentId is undefined or invalid");
        return; // Exit the function if paymentIntentId is not valid
      }

      const maxAttempts = 5; // Maximum number of attempts
      const delayBetweenAttempts = 2000; // Delay between attempts in milliseconds (e.g., 2000 milliseconds = 2 seconds)

      let attempt = 0; // Current attempt

      const attemptFetch = async () => {
        try {
          const querySnapshot = await getDocs(
            query(collection(db, "orders"), where("id", "==", paymentIntentId))
          );
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              setOrderDetails({ ...doc.data(), docId: doc.id });
              //console.log("Document data:", doc.data());
            });
          } else if (attempt < maxAttempts) {
            // console.log(
            //   `No such document on attempt ${attempt + 1}. Retrying...`
            // );
            setTimeout(() => {
              attempt++;
              attemptFetch(); // Retry fetching the document
            }, delayBetweenAttempts);
          } else {
            console.log("No such document after maximum attempts.");
          }
        } catch (error) {
          console.error("Error fetching order details:", error);
        }
      };

      attemptFetch(); // Initial fetch attempt
      clearCart();
    };

    // Call fetchDetails and wait for 3 seconds to simulate loading
    fetchOrderDetails().then(() => {
      setTimeout(() => {
        setLoading(false); // Stop loading after 3 seconds
      }, 3000);
    });

    // if (itemId) {
    //   fetchProductDetails();
    // }
    if (paymentIntentId) {
      fetchOrderDetails();
    }
  }, [paymentIntentId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ margin: "30px" }}>
      <h1>Payment Successful!</h1>
      <p>
        Thank you for your purchase. Your payment has been processed
        successfully.
      </p>

      {/* Display order summary here */}
      <div
        style={{ marginTop: "20px", marginBottom: "20px", fontSize: "11px" }}
      >
        <h2>Order Summary</h2>
        {/* Map through order items and display them */}
        <div >{productDetails.length > 0 ? (
            productDetails.map((item, index) => (
              <div key={index}>
                <h2 style={{ marginTop: "20px", marginBottom: "20px", fontSize: "11px" }}>Course: {item.eventName}</h2>
                <div>Amount: €{(orderDetails.amount / 100).toFixed(2)}</div>
                {/* Display more product details as needed */}
              </div>
            ))
          ) : (
            <p>No product details available.</p>
          )}</div>
        <div>Order ID: {orderDetails.docId}</div>
        <div>
          Created: {new Date(orderDetails.created * 1000).toLocaleString()}
        </div>
     {/*    <div>Venue: {productDetails.venue}</div> */}
     
      
      </div>

      <div
        style={{ marginTop: "20px", marginBottom: "20px", fontSize: "14px" }}
      >
        {/* Provide links or buttons for next steps */}
        <Link to="/orders" style={{ paddingRight: "20px" }}>
          View Your Orders
        </Link>
        <Link to="/" className="continue-shopping-btn">
          Check out other events
        </Link>
      </div>

      <div className="support-info">
        <p>
          If you have any questions about your order, please contact us at
          luna.parque.events@gmail.com
        </p>
      </div>

      {/* Include any other elements you need, such as social media links, footer, etc. */}
    </div>
  );
}
