import React, { useState, useEffect } from "react";
import { auth, googleProvider, db } from "../config/firebase.js";
import {
  getFirestore,
  query,
  where,
  doc,
  collection,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Users from "./users.js";
import { useAuth } from "../../src/components/auth-email.js";
import ToggleSwitch from "../components/toggle.js";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
  LineChart,
} from "recharts";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import "..//pages/allCSS.css";
import UserPromptModal from "..//../src/components/userPrompt.js";

function IncentiveSearch() {
  const { currentUser } = useAuth(); // Using a custom hook to get the current user
  const [atecoL3, setAtecoL3] = useState("");
  const [regione, setRegione] = useState("");
  const [firstName, setFirstName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyEmployees, setCompanyEmployees] = useState("");
  const [companyTurnover, setCompanyTurnover] = useState("");
  const [Descrizione, setDescrizione] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [incentives, setIncentives] = useState("");
  const [documents, setDocuments] = useState([]);
  const [incentivesCount, setIncentivesCount] = useState(0);
  const [totalIncentives, setTotalIncentives] = useState(0);
  const [totalFunding, setTotalFunding] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [incentivesData, setIncentivesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoadingIncentives, setIsLoadingIncentives] = useState(true);
  const [isLoadingFunding, setIsLoadingFunding] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const [cw, setCw] = useState(""); // Empty string as initial value or a default value "0" or "1"
  const [lastDataUpdate, setLastDataUpdate] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  useEffect(() => {
    async function fetchUserData() {
      if (currentUser && currentUser.email) {
        const usersCollectionRef = collection(db, "users");
        const q = query(
          usersCollectionRef,
          where("email", "==", currentUser.email)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          setAtecoL3(userData.companyAteco);
          setRegione(userData.regione);
          fetchIncentivesData(
            userData.companyAteco,
            userData.regione,
            userData.companySize
          );
          setFirstName(userData.firstName);
          setCompanyName(userData.companyName);
          setCompanySize(userData.companySize);
          setCompanyEmployees(userData.companyEmployees);
          setCompanyTurnover(userData.companyTurnover);
          //startNewConversation(userData.firstName);
        } else {
          console.log("No user data found for email:", currentUser.email);
        }
      }
    }

    /* async function fetchIncentivesData(atecoL3, regione) {
      const cwValue = cw;
      const requestBody = {
        codicel3: atecoL3 ? atecoL3.split(" - ")[0].trim() : "", // Make sure to check for existence
        regione: regione || "",
        cw: cwValue,
      };
      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${API_BASE_URL}/api/incentives`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        }); 
        if (!response.ok)
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        const ids = await response.json();
        console.log("Base Incentive IDs received from server:", ids);
        // Caching the IDs in local storage
        localStorage.setItem("baseIncentiveIDs", JSON.stringify(ids));
        loadIncentivi(false);
        // Use the IDs as needed in your component
      } catch (error) {
        console.error("Error fetching base incentives data:", error);
      }
    }

    fetchUserData();
  }, [currentUser, cw]); */

    async function fetchIncentivesData(atecoL3, regione, companySize) {
      const extractBaseCode = (ateco) => {
        // Log the original ATECO code
        //console.log("Original ATECO code:", ateco);
        
        // Extract the numerical part of the ATECO code before any non-digit characters
        const numericalPartMatch = ateco.match(/^(\d{2}\.\d)(\d?)\b/);
        let numericalPart = "";

        if (numericalPartMatch) {
            const beforeDecimal = numericalPartMatch[1]; // e.g., "27."
            const afterDecimal = numericalPartMatch[2];  // e.g., "9" or ""
            numericalPart = afterDecimal.length === 0 ? `${beforeDecimal}0` : `${beforeDecimal}${afterDecimal}`;
        }

        // Log the corrected ATECO code
        //console.log("Corrected ATECO code:", numericalPart);

        // Return the corrected numerical part
        return numericalPart;
    };

    const requestBody = {
        codicel3: atecoL3 ? extractBaseCode(atecoL3) : "",
        regione: regione || "",
        companySize: companySize,
    };

      //console.log("Sending to server:", requestBody);

      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${API_BASE_URL}/api/incentives`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.statusText}`
          );
        }

        let filteredData = await response.json();

        // Enhance each incentive with a 'filtered' field set to 1
        filteredData = filteredData.map((incentive) => ({
          ...incentive,
          filtered: 1,
        }));

        // Store the complete incentives data in local storage
        localStorage.setItem("filteredData", JSON.stringify(filteredData));
        //console.log("Incentives data received from server:", filteredData);
      } catch (error) {
        console.error("Error fetching incentives data:", error);
      }
    }

    fetchUserData();
  }, [currentUser]);

  const [filters, setFilters] = useState({
    CW: "",
    Agevolazione: "",
    Fondo_perduto: "",
    Capitale_di_rischio: "",
    Prestito: "",
    Interventi_garanzia: "",
    Anticipo_rimborsabile: "",
  });

  const [usingFiltered, setUsingFiltered] = useState(false);

  useEffect(
    () => {
      const storedData = JSON.parse(localStorage.getItem("filteredData")) || [];
      const filteredIds = storedData
        .filter((incentive) => incentive.filtered === 1)
        .map((incentive) => incentive.ID_Incentivo);

      if (filteredIds.length > 0) {
        fetchIncentiviByIds(filteredIds).then((fetchedIncentives) => {
          setIncentives(fetchedIncentives);
        });
      } else {
        setIncentives([]); // Ensure that no incentives are displayed when none are filtered
      }
    },
    [filters],
    [lastDataUpdate]
  );

  const fetchIncentiviByIds = async (ids) => {
    const db = getFirestore();
    const incentiviCollection = collection(db, "incentivi");
  
    const promises = ids.map((id) => {
      const docRef = doc(incentiviCollection, id);
      return getDoc(docRef);
    });
  
    try {
      const documentSnapshots = await Promise.all(promises);
      const documents = documentSnapshots
        .map((docSnapshot) =>
          docSnapshot.exists()
            ? { id: docSnapshot.id, ...docSnapshot.data() }
            : null
        )
        .filter((doc) => doc !== null); // Filtering out null values if document doesn't exist
  
      // Sort documents by Data_apertura in descending order
      documents.sort((a, b) => {
        const dateA = new Date(a.Data_apertura);
        const dateB = new Date(b.Data_apertura);
        return dateB - dateA; // descending order
      });
  
      return documents;
    } catch (error) {
      console.error("Error fetching documents by IDs:", error);
      return []; // Returning an empty array on error ensures the UI can handle this gracefully
    }
  };

  function handleFilterChange(field, value) {
    //console.log(`Filter change triggered for ${field} with value ${value}`);

    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [field]: value };
      //console.log(`Updated filters:`, updatedFilters);

      const currentFilteredData =
        JSON.parse(localStorage.getItem("filteredData")) || [];
      const updatedIncentives = currentFilteredData.map((incentive) => {
        const shouldFilter = Object.keys(updatedFilters).every((key) => {
          return (
            updatedFilters[key] === "" || incentive[key] === updatedFilters[key]
          );
        });

        return {
          ...incentive,
          filtered: shouldFilter ? 1 : 0,
        };
      });

      localStorage.setItem("filteredData", JSON.stringify(updatedIncentives));
      //console.log(`Local storage updated with filtered data.`);

      // Force an update to re-fetch and re-render
      setUsingFiltered((u) => !u); // Toggle the usingFiltered state to trigger useEffect

      return updatedFilters;
    });
  }

  const [filteredCount, setFilteredCount] = useState(0); // State to store the count

  useEffect(() => {
    //console.log("Effect triggered by filters or data update");

    const storedData = JSON.parse(localStorage.getItem("filteredData")) || [];
    const filteredIds = storedData
      .filter((incentive) => incentive.filtered === 1)
      .map((incentive) => incentive.ID_Incentivo);

    setFilteredCount(filteredIds.length);
    //console.log("Updated filtered count:", filteredIds.length);

    if (filteredIds.length > 0) {
      fetchIncentiviByIds(filteredIds).then((fetchedIncentives) => {
        setIncentives(fetchedIncentives);
        //console.log("Incentives updated based on filtered IDs");
      });
    } else {
      setIncentives([]);
      //console.log("No filtered incentives to display");
    }
  }, [filters, lastDataUpdate]);

  ///////////////////////////////////////
  ///////reset filters when page mounts

  const resetFilters = () => {
    // Fetch the current filtered data from local storage
    const storedData = JSON.parse(localStorage.getItem("filteredData")) || [];

    // Set all 'filtered' properties to 1
    const updatedData = storedData.map((incentive) => ({
      ...incentive,
      filtered: 1, // Resetting all to visible
    }));

    // Update local storage with the modified data
    localStorage.setItem("filteredData", JSON.stringify(updatedData));

    // Update the state used to display incentives
    fetchIncentiviByIds(
      updatedData
        .filter((incentive) => incentive.filtered === 1)
        .map((incentive) => incentive.ID_Incentivo)
    ).then((fetchedIncentives) => {
      setIncentives(fetchedIncentives);
    });

    // Optionally, reset any filter states if being used to track current filters
    setFilters({
      CW: "",
      Agevolazione: "",
      Fondo_perduto: "",
      Capitale_di_rischio: "",
      Prestito: "",
      Interventi_garanzia: "",
      Anticipo_rimborsabile: "",
    });
  };

  useEffect(() => {
    // Call resetFilters on the initial load of the component
    resetFilters();
  }, []);

  //////////////////////////////////////////////////////////////////////////
  ////////toggle

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////send and receive the keywords, ateco & regione to server

  /* const sendSearch = async (Descrizione, useFiltered = false) => {
    const idsKey = useFiltered ? "filteredIncentiveIDs" : "baseIncentiveIDs"; // Choose the appropriate key
    const storedIDs = localStorage.getItem(idsKey);
    const ids = storedIDs ? JSON.parse(storedIDs) : [];

    if (!currentUser || ids.length === 0) {
      console.error(
        "User information is missing or no incentive IDs available."
      );
      return;
    }

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${API_BASE_URL}/api/sendSearch`;
    const requestBody = {
      ids: ids,
      Descrizione: description,
    };

    //console.log("Sending request to server with body:", requestBody); // Log the request body to see what is being sent

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      //await handleServerResponse(response); // Process response to extract and store IDs
    } catch (error) {
      console.error("Error sending search to server:", error);
    }
  }; */

  const sendSearch = async (Descrizione, setLastDataUpdate) => {
    setIsLoading(true); // Start loading
    //console.log("Start sendSearch with Description:", Descrizione);

    const storedData = localStorage.getItem("filteredData");
    let incentives = storedData ? JSON.parse(storedData) : [];
    //console.log("Loaded incentives from localStorage:", incentives);

    const ids = incentives
      .filter((incentive) => incentive.filtered === 1)
      .map((incentive) => incentive.ID_Incentivo);
    //console.log("Filtered IDs to send:", ids);

    if (!currentUser || ids.length === 0) {
      console.error(
        "User information is missing or no incentive IDs available."
      );
      return;
    }

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${API_BASE_URL}/api/sendSearch`;
    const requestBody = {
      ids: ids,
      Descrizione: Descrizione,
    };

    //console.log("Sending request to server with body:", requestBody);

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      //console.log("Response data received:", responseData);

      if (!responseData.incentivi_info || responseData.incentivi_info.length === 0) {
        //console.log("Nessun incentivo trovato");
        alert("Nessun incentivo trovato"); // Alert user if no incentives are returned from the server
      } else {
        const returnedIDs = responseData.incentivi_info.map(
          (item) => item.ID_Incentivo
        );
        //console.log("Returned IDs from server:", returnedIDs);

        incentives = incentives.map((incentive) => ({
          ...incentive,
          filtered: returnedIDs.includes(incentive.ID_Incentivo) ? 1 : 0,
        }));
        //console.log("Updated incentives after response:", incentives);
        localStorage.setItem("filteredData", JSON.stringify(incentives));
        setLastDataUpdate(Date.now()); // Update state to trigger re-render
      }
    } catch (error) {
      console.error("Error sending search to server:", error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  /////////*get ID info using ids from local storage as a filter to FB

  /* const [loading, setLoading] = useState(false);
  const [usingFiltered, setUsingFiltered] = useState(false);

  const loadIncentivi = async (useFiltered) => {
    setLoading(true);
    let ids = [];
    const idsKey = useFiltered ? "filteredIncentiveIDs" : "baseIncentiveIDs";
    const cachedIDs = localStorage.getItem(idsKey);
    ids = cachedIDs ? JSON.parse(cachedIDs) : [];

    if (ids.length === 0 && useFiltered) {
      // If no filtered IDs, try base IDs
      const baseIDs = localStorage.getItem("baseIncentiveIDs");
      ids = baseIDs ? JSON.parse(baseIDs) : [];
      setUsingFiltered(false); // Update state if fallback to base IDs
    }

    if (ids.length > 0) {
      const fetchedDocuments = await fetchIncentiviByIds(ids);
      setDocuments(fetchedDocuments);
    } else {
      console.log("No IDs available to load documents.");
      setDocuments([]);
    }
    setLoading(false);
  };

  // Effect to load incentives
  useEffect(() => {
    loadIncentivi(usingFiltered);
  }, [usingFiltered]);

  const fetchIncentiviByIds = async (ids) => {
    const db = getFirestore();
    const incentiviCollection = collection(db, "incentivi");
    const promises = ids.map((id) => {
      const docRef = doc(incentiviCollection, id);
      return getDoc(docRef);
    });

    try {
      const documentSnapshots = await Promise.all(promises);
      return documentSnapshots
        .map((docSnapshot) =>
          docSnapshot.exists()
            ? { id: docSnapshot.id, ...docSnapshot.data() }
            : null
        )
        .filter((doc) => doc !== null);
    } catch (error) {
      console.error("Error fetching documents by IDs:", error);
      return [];
    }
  }; */

  ////////////////////////////////////////////
  //////update storage based on keywords response
  /* const handleServerResponse = async (response, useFiltered) => {
    const data = await response.json();
    console.log("Received response from server:", data);

    if (data.incentivi_info) {
      const ids = data.incentivi_info.map((item) => item.ID_Incentivo);
      console.log("Extracted IDs:", ids);
      const key = useFiltered ? "filteredIncentiveIDs" : "baseIncentiveIDs";
      localStorage.setItem(key, JSON.stringify(ids));
      setUsingFiltered(useFiltered); // Ensure the component knows which IDs are being used
      await loadIncentivi(useFiltered); // Directly invoke loading with correct IDs
    } else {
      console.error("No incentivi_info found in the response.");
    }
  };

  const updateLocalStorageWithIDs = (ids, isFiltered = false) => {
    const key = isFiltered ? "filteredIncentiveIDs" : "baseIncentiveIDs";
    localStorage.setItem(key, JSON.stringify(ids));
    console.log(
      `Local storage updated with new ${
        isFiltered ? "filtered" : "base"
      } incentive IDs.`
    );
  };

  ////make a count
  const [idCount, setIdCount] = useState(0);

  useEffect(() => {
    const loadIDs = () => {
      const idsKey = usingFiltered
        ? "filteredIncentiveIDs"
        : "baseIncentiveIDs";
      const storedIDs = localStorage.getItem(idsKey);
      const ids = storedIDs ? JSON.parse(storedIDs) : [];
      setIdCount(ids.length); // Update the state with the count of IDs
      setIsLoadingIncentives(false); // Update loading state
    };

    loadIDs();
  }, [usingFiltered]);

  const formatResponseMessage = (message) => {
    // Split the message by the pattern which appears before each ID number
    const parts = message.split(/(?=\d+\. \*\*)/g); // This regex looks for any digit followed by ". **" and uses it as a split point but includes it in the results
    return (
      <div>
        {parts.map((part, index) => (
          <p key={index}>{part}</p> // Each part is put in a new paragraph
        ))}
      </div>
    );
  };

  ///////////////////////////////////////////////
  /////charting & incentive info
  function IncentivesChart({ data }) {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
          <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
          <Tooltip />
          <Legend />
          <Bar yAxisId="left" dataKey="total_incentives" fill="#092f7a" />
        </BarChart>
      </ResponsiveContainer>
    );
  } */

  const formatDescription = (desc) => {
    if (typeof desc === "string") {
      desc = desc.trim(); // Trim whitespace at the start and end

      // Remove everything before and including the first colon on the first line
      desc = desc.replace(/^[^:]*:\s*/, "");

      // Normalize new lines and handle the initial bullet point
      let lines = desc.split(/\n+/); // Split the description into lines
      lines = lines.reduce((filteredLines, line, index) => {
        line = line.trim(); // Trim each line to remove leading/trailing whitespace

        // Skip empty lines to avoid adding extra spaces
        if (line === "") {
          return filteredLines;
        }

        // Handle numbered lines to not start with a bullet
        if (/^\d+\./.test(line)) {
          filteredLines.push(line); // Return the line as is if it starts with a number and a period
        } else if (index > 0 && filteredLines.length > 0) {
          // Apply bullets to lines that are not the first line and not after an empty line
          filteredLines.push("• " + line);
        } else {
          filteredLines.push(line); // First line or any line not fitting the above criteria gets no bullet
        }

        return filteredLines;
      }, []);

      // Join the lines back together, inserting a newline between each
      return lines.join("\n");
    }
    return "No description available"; // Return a default message if no description is provided
  };

  const getCategoryClass = (category) => {
    const categoryClasses = {
      "Crisi d'impresa": "crisi-impresa",
      Digitalizzazione: "digitalizzazione",
      "Imprenditoria femminile": "imprenditoria-femminile",
      "Innovazione e ricerca": "Innovazione-ricerca",
      "Sostegno investimenti": "Sostegno-investimenti",
      "Start up/Sviluppo d'impresa": "Startup-impresa",
      "Crisi d'impresa": "Crisi-impresa",
      "Inclusione sociale": "Inclusione-sociale",
      "Imprenditoria giovanile": "Imprenditoria-giovanile",
      Internazionalizzazione: "Internazionalizzazione",
      "Transizione ecologica": "Transizione-ecologica",
      // Add more mappings here...
    };
    return categoryClasses[category] || "default-category"; // Fallback class
  };

  const formatDate = (dateString) => {
    return dateString
      ? dateString.split("T")[0]
      : "Aperto fino a nuova communicazione"; // Default to 'N/A' if dateString is undefined
  };

  const formatCurrency = (value) => {
    if (value) {
      const millions = Number(value) / 1000000;
      return `€${millions.toFixed(2)}M`;
    }
    return "N/A"; // Default case if the value is not provided
  };

  const isYear2024 = (dateString) => {
    if (!dateString) return false; // Guard clause for undefined or empty input
    const year = new Date(dateString.split("T")[0]).getFullYear(); // Get the year from the date
    return year === 2024;
  };

  const placeholders = [
    "SMART&START",
    "PNRR",
    "CREDITO",
    "INTERNAZIONALE",
    "INFRASTRUTTURE",
    "TASSO ZERO",
    "AGEVOLAZIONI",
  ];
  const [currentPlaceholder, setCurrentPlaceholder] = useState(placeholders[0]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      // Update the placeholder text by cycling through the placeholders array
      currentIndex = (currentIndex + 1) % placeholders.length;
      setCurrentPlaceholder(placeholders[currentIndex]);
    }, 1000); // Change placeholder every 2000 milliseconds (2 seconds)

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  //////////////////////////////////////////////////////////////////////////////
  /////////////////
  /// Nav to ThreadUnic
  const navigate = useNavigate();
  const [threadId, setThreadId] = useState("");
  const [currentThreadId, setCurrentThreadId] = useState(null);
 
  const startNewConversation = async (
    atecoL3,
    regione,
    assistant,
    incentiveId,
    firstName,
    companyName,
    companySize,
    companyEmployees,
    companyTurnover
  ) => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const userMessage = `Ciao, e questo incentivo buona per nostra azienda? Io sono ${firstName} e l'azienda si chiama ${companyName}. Siamo in ${regione} con ateco: ${atecoL3}. Siamo un ${companySize} con ${companyEmployees} dipendenti e turnover annuale di €${companyTurnover}.`;
      const response = await fetch(`${API_BASE_URL}/api/start_new_convo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: userMessage }),
      });
      //console.log("API Base URL:", API_BASE_URL);
      console.log("firstName:", firstName);
      //console.log("companyName:", companyName);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      //console.log("Response data:", data); // Log the entire response data
      localStorage.setItem("currentThreadId", data.thread_id);
      //console.log("Assistant parameter:", assistant);
      //console.log("Stored thread_id in localStorage:", data.thread_id);

      if (data.thread_id && assistant) {
        //console.log("New thread ID:", data.thread_id, "assistant:", assistant);
        const title = `Discussion on ATECO ${atecoL3} in ${regione}`;
        // Navigate to the threadUnic page with the thread ID and assistant ID in the URL
        navigate(`/threadUnic/${assistant}/${data.thread_id}/${incentiveId}`, {
          state: { title: title },
        });
      } else {
        console.error(
          "Thread ID or Assistant ID was not returned from the server."
        );
      }
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="searchy-container">
      {currentThreadId ? (
        <p>Current Thread ID: {currentThreadId}</p>
      ) : (
        <p>Loading new conversation...</p>
      )}
      {!isAuthenticated && <UserPromptModal />}
      {/*   <div>
            {loading ? <p>Loading...</p> : (
                <ul>
                    {documents.map(doc => (
                        <li key={doc.id}>
                            <h4>{doc.name}</h4>
                            <p>ID: {doc.id}</p>
                         
                        </li>
                    ))}
                </ul>
            )}
            <button onClick={() => setUsingFiltered(false)}>
                Show Base IDs
            </button>
        </div>
    <input
    type="checkbox"
    checked={useFiltered}
    onChange={e => setUseFiltered(e.target.checked)}
    id="useFilteredToggle"
/>
<label htmlFor="useFilteredToggle">Use Filtered IDs</label>

// Button to initiate search
<button onClick={() => sendSearch("your description here", useFiltered)}>
    Search
</button> */}
      <div>
        <div className="agent2-containers">
          <div className="incentivy-container">
            <h1
              style={{
                margin: "10px 0 20px 0",
                color: "#092f7a",
                textAlign: "center",
                color: "white",
              }}
            >
              <div>
                {companyName
                  ? companyName
                  : "No user data available or user not signed in."}
                :{" "}
                {firstName
                  ? capitalizeFirstLetter(firstName)
                  : "No user data available or user not signed in."}
                , ecco i potenziali incentivi per te. Stiamo filtrando per regione, ateco
                e dimensione aziendale.
              </div>
            </h1>
            <table
              style={{
                margin: "auto",
                borderCollapse: "separate", // This prevents borders from collapsing
                borderSpacing: "0", // Removes default spacing to enable full control over borders
              }}
            >
              <tbody>
                <tr>
                  <th
                    style={{
                      border: "solid 1px white",
                      padding: "5px",
                      color: "white", // Rounded corners on the left side of the header row
                    }}
                  >
                    Ateco
                  </th>
                  <td
                    style={{
                      border: "solid 1px white",
                      padding: "5px",
                      color: "white",
                    }}
                  >
                    {atecoL3
                      ? atecoL3
                      : "No user data available or user not signed in."}
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      border: "solid 1px white",
                      padding: "5px",
                      color: "white",
                    }}
                  >
                    Regione
                  </th>
                  <td style={{ border: "solid 1px white", color: "white" }}>
                    {regione
                      ? regione
                      : "No user data available or user not signed in."}
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      border: "solid 1px white",
                      padding: "5px",
                      color: "white",
                    }}
                  >
                    Size
                  </th>
                  <td style={{ border: "solid 1px white", color: "white" }}>
                    {companySize
                      ? companySize
                      : "No user data available or user not signed in."}
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      border: "solid 1px white",
                      padding: "5px",
                      color: "white",
                    }}
                  >
                    Incentivi
                  </th>
                  <td style={{ border: "solid 1px white", color: "white" }}>
                    {filteredCount}
                  </td>
                </tr>
                {/*  <tr>
                <th
                  style={{
                    border: "solid 1px white",
                    padding: "5px",
                    color: "white",
                  }}
                >
                  n. Incentivi Dedicati
                </th>
                <td style={{ border: "solid 1px white", color: "white" }}>
                  {isLoadingIncentives ? "Caricando..." : idCount}
                </td>
              </tr> */}
                {/*  <tr>
                <th
                  style={{
                    border: "solid 1px grey",
                    padding: "5px",
                    color: "grey",
                  }}
                >
                  Total Funding
                </th>
                <td
                  style={{
                    border: "solid 1px grey",
                    color: "grey",
                  }}
                >
                  {isLoadingFunding
                    ? "Caricando..."
                    : `${totalFunding.toLocaleString("en-US")} Euros`}
                </td>
              </tr> */}
              </tbody>
            </table>
            <div 
              style={{ fontSize: "20px", color: "white", marginTop: "25px"}}
            >
              FILTRI
            </div>
            <div
              style={{
                display: "flex", // Enables flexbox
                flexDirection: "column", // Stacks children vertically
                padding: "20px",
                color: "white",
                alignItems: "center", // Aligns children at the start of the flex container
              }}
            >
              <ToggleSwitch
                label="Settimana Corrente"
                onChange={(value) => handleFilterChange("CW", value)}
              />
              <ToggleSwitch
                label="Agevolazione"
                onChange={(value) => handleFilterChange("Agevolazione", value)}
              />
              <ToggleSwitch
                label="Fondo Perduto"
                onChange={(value) => handleFilterChange("Fondo_perduto", value)}
              />
              <ToggleSwitch
                label="Capitale di rischio"
                onChange={(value) =>
                  handleFilterChange("Capitale_di_rischio", value)
                }
              />
              <ToggleSwitch
                label="Prestito"
                onChange={(value) => handleFilterChange("Prestito", value)}
              />
              <ToggleSwitch
                label="Interventi Garanzia"
                onChange={(value) =>
                  handleFilterChange("Interventi_garanzia", value)
                }
              />
              <ToggleSwitch
                label="Anticipo rimborsabile"
                onChange={(value) =>
                  handleFilterChange("Anticipo_rimborsabile", value)
                }
              />
              {/*   <button
                onClick={resetFilters}
                style={{ margin: "10px", padding: "5px 20px", cursor: "pointer" }}
              >
                Show All Incentives
              </button> */}
            </div>
            {/* <div>
              <div>
                <select
                  value={filters.CW}
                  onChange={(e) => handleFilterChange("CW", e.target.value)}
                >
                  <option value="">All</option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
                <select
                  value={filters.Agevolazione}
                  onChange={(e) =>
                    handleFilterChange("Agevolazione", e.target.value)
                  }
                >
                  <option value="">All</option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
                <select
                  value={filters.Fondo_perduto}
                  onChange={(e) =>
                    handleFilterChange("Fondo_perduto", e.target.value)
                  }
                >
                  <option value="">All</option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
                <select
                  value={filters.Capitale_di_rischio}
                  onChange={(e) =>
                    handleFilterChange("Capitale_di_rischio", e.target.value)
                  }
                >
                  <option value="">All</option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
                <select
                  value={filters.Prestito}
                  onChange={(e) =>
                    handleFilterChange("Prestito", e.target.value)
                  }
                >
                  <option value="">All</option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
                <select
                  value={filters.Interventi_garanzia}
                  onChange={(e) =>
                    handleFilterChange("Interventi_garanzia", e.target.value)
                  }
                >
                  <option value="">All</option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
                <select
                  value={filters.Anticipo_rimborsabile}
                  onChange={(e) =>
                    handleFilterChange("Anticipo_rimborsabile", e.target.value)
                  }
                >
                  <option value="">All</option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
              </div>
              <button
                onClick={resetFilters}
                style={{ margin: "10px", padding: "5px 20px" }}
              >
                Show All Incentives
              </button>
            </div> */}
          </div>
        </div>

        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
            fontSize: "16px",
          }}
        >
          <div
            className="incentivy-container"
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              color: "#092f7a",
              padding: "10px",
              textAlign: "center",
            }}
          >
            <h1 style={{ fontSize: "20px", marginTop: "20px", color: "white" }}>
              Keywords Ricerca
            </h1>
            <div>
              <input
                type="text"
                placeholder={currentPlaceholder}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                  width: "300px",
                  marginRight: "10px",
                  height: "40px",
                  fontSize: "18px",
                }}
              />
              <button
                className="button-stylish"
                onClick={() => sendSearch(description, setLastDataUpdate)}
                disabled={isLoading} // Disable button while loading
              >
                VAI
              </button>
              {isLoading && <div className="spinner-sendsearch"></div>}
            </div>
          </div>
          <div>
            <div style={{ textAlign: "left", fontSize: "16px" }}>
              <div>
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                {/* {responseMessage && formatResponseMessage(responseMessage)} */}
              </div>
            </div>
            <div>
              <div
                className="incentivy-container"
                style={{ background: "#092f7a" }}
              >
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                <h1
                  style={{
                    marginTop: "20px",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Incentivi Dedicati
                </h1>
                {incentives.length > 0 ? (
                  <ul style={{ padding: "10px", listStyleType: "none" }}>
                    {incentives.map((doc, index) => (
                      <li
                        key={index}
                        style={{
                          marginBottom: "20px",
                          border: "1px solid #ccc",
                          padding: "20px",
                          borderRadius: "8px",
                          boxShadow:
                            "0 4px 6px rgba(0,0,0,0.1), 5px 5px 5px #FF5758",
                          backgroundColor: "#fff", // adds a clean white background
                          lineHeight: "2rem",
                          fontSize: "20px",
                          position: "relative", // Needed for absolute positioning of the arrow
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "2px",
                            right: "10px",
                          }}
                        >
                          <button
                              className="button-stylish"
                              onClick={() => {
                                //console.log("Document data:", doc); // Log the entire document
                                const assistant = doc.AssistantID; // Rename AssistantID to assistant
                                startNewConversation(
                                  atecoL3,
                                  regione,
                                  assistant,
                                  doc.ID_Incentivo,
                                  firstName,
                                  companyName,
                                  companySize,
                                  companyEmployees,
                                  companyTurnover
                                );
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              →
                            </button>
                        </div>
                        {isYear2024(doc.Data_apertura) && (
                          <button
                          className="button-stylish"
                          onClick={() => {
                            //console.log("Document data:", doc); // Log the entire document
                            const assistant = doc.AssistantID; // Rename AssistantID to assistant
                            startNewConversation(
                              atecoL3,
                              regione,
                              assistant,
                              doc.ID_Incentivo,
                              firstName,
                              companyName,
                              companySize,
                              companyEmployees,
                              companyTurnover
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        >
                            NUOVO
                          </button>
                        )}
                        <h2 style={{ marginTop: "20px" }}>{doc.Titolo}</h2>
                        <strong>
                          {doc.KeyBenefit} <br />
                        </strong>
                        <strong>Apertura:</strong>{" "}
                        {formatDate(doc.Data_apertura)}{" "}
                        <strong>Chiusura:</strong>{" "}
                        {formatDate(doc.Data_chiusura)}
                        <br />
                        <div>
                          {doc.Obiettivo_Finalita.map((goal) => (
                            <span
                              key={goal}
                              className={`category-box ${getCategoryClass(
                                goal
                              )}`}
                            >
                              {goal}
                            </span>
                          ))}
                        </div>
                        <pre className="description-box">
                          {formatDescription(doc.Summary)}
                        </pre>
                        {/*   <br />
                      <button
                        className="button-stylish"
                        onClick={() =>
                          startNewConversation(
                            atecoL3,
                            regione,
                            doc.assistant
                          )
                        }
                      >
                        Scopri di più e candidati
                      </button>
                      <br /><br /> */}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>Aggiungi KEYWORDS</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncentiveSearch;
