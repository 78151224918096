import "..//pages/allCSS.css";
import React, { useState, useEffect } from 'react';
import { db } from "../config/firebase.js";
import { doc, getDoc } from "firebase/firestore";
import { useCart } from './cartContext.js';
import { useNavigate } from "react-router-dom";

const SubscriptionPlans = () => {
    const navigate = useNavigate();
    const handleCheckout = () => navigate('/cart');

    return (
        <div className="subscription-container">
            <header className="subscription-header">
                <title>Abbonamenti per ogni fase</title>
                <h1>Abbonamenti per ogni fase</h1>
                <p>Esplora i nostri piani tecnologici, ciascuno progettato per migliorare il processo di richiesta di sovvenzioni con software potenti e supporto esperto.</p>
            </header>
            <section className="subscription-piani">
                <div className="subscription-piano subscription-essenziale">
                    <h2>Essenziale</h2>
                    <p className="subscription-prezzo">€0 <span>€15</span> /mese</p>
                    <p className="subscription-subject">Non perdere nessuna opportunità: il nostro piano gratuito offre corrispondenza e pianificazione delle sovvenzioni per aiutare le startup e le piccole imprese a cogliere ogni nuova opportunità.</p>
                    <ul>
                        <li className="subscription-tick"><i class="fas fa-check"></i> Matching con sovvenzioni alimentato da AI</li>
                        <li className="subscription-tick"><i class="fas fa-check"></i> Verifica iodenita con l'Agente IA</li>
                        <li className="subscription-tick"><i class="fas fa-check"></i> Newsletter e Alerts per non perdere nulla</li>
                        <li className="subscription-tick"><i class="fas fa-check"></i> Accesso al market place</li>
                    </ul>
                    
                    <button onClick={handleCheckout} href="#" className="subscription-button">Iscriviti Gratis</button>
                </div>
                <div className="subscription-piano subscription-completo">
                    <h2>Completo</h2>
                    <p className="subscription-prezzo">€75 <span>€150</span> /mese</p>
                    <p className="subscription-subject">Il nostro piano Completo offre una strategia di sovvenzione personalizzata, opportunità mensili di candidatura e supporto avanzato AI per rafforzare i tuoi sforzi di sovvenzione indipendenti.</p>
                    <ul>
                        <li className="subscription-tick"><i class="fas fa-check"></i> Tutte le funzionalità del piano Essenziale</li>
                        <li className="subscription-tick"><i class="fas fa-check"></i> Configurazione strategica del piano di sovvenzioni annuale personalizzato proposta dal IA</li>
                        <li className="subscription-tick"><i class="fas fa-check"></i> 1 generazioni di pre-applicazione al mese</li>
                        <li className="subscription-tick"><i class="fas fa-check"></i> Note: ogni richiesta aggiuntiva di pre-applicazione a un prezzo di 100€</li>
                    </ul>
                    <a href="#" className="subscription-button">Abbonati Ora</a>
                </div>
                <div className="subscription-piano subscription-ultra">
                    <h2>Ultra</h2>
                    <p className="subscription-prezzo">€150 <span>€250</span> /mese</p>
                    <p className="subscription-subject"> Massimizza il tuo potenziale: ideato per i richiedenti abituali di finanziamenti e partner strategici come incubatori, avvocati e consulenti che necessitano di supporto completo e maggiore capacità.</p>
                    <ul>
                    <li className="subscription-tick"><i class="fas fa-check"></i> Capacità AI illimitate</li>
                        <li className="subscription-tick"><i class="fas fa-check"></i> Gestione Multi-Aziendale</li>
                        <li className="subscription-tick"><i class="fas fa-check"></i> Account Manager dedicato</li>
                        <li className="subscription-tick"><i class="fas fa-check"></i> Configurazione strategica del piano di sovvenzioni annuale personalizzato per ogni azienda con i nostri esperti</li>
                    </ul>
                    <a href="#" className="subscription-button">Parla con Noi</a>
                </div>
            </section>
            <footer className="subscription-footer">
                <p>Unisciti a oltre 2500+ aziende che stanno già crescendo con Incentivato.it</p>
                <a href="#" className="subscription-button">Inizia subito</a>
            </footer>
        </div>
    );
};

export default SubscriptionPlans;
