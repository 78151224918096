import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

const UserPromptModal = ({ onClose }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogIn = () => {
    // Redirect to the login page, preserving the original location
    navigate('/login', { state: { from: location } });
  };

  useEffect(() => {
    // Disable scrolling on the body
    document.body.style.overflow = 'hidden';
    return () => {
      // Re-enable scrolling when the component unmounts
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div 
      className="user-prompt-modal"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000, // Ensure this is high enough to be on top of other content
      }}
    >
      <div className="modal-content" tabIndex={-1} style={{ zIndex: 1001 }}>
        <h2>Benvenuto!</h2>
        <p>Registrati (Signup) o accedi (Login) per continuare a utilizzare il nostro sito.</p>
        <div className="modal-actions">
          <Link to="/signup"><button>Sign Up</button></Link>
          <button onClick={handleLogIn}>Log In</button>
          {/* Removed onClose button to prevent closing without action */}
        </div>
      </div>
    </div>
  );
};

export default UserPromptModal;
