///imports
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { db, auth } from "..//..//src/config/firebase.js";
import {
  getDocs,
  getDoc,
  collection,
  addDoc,
  serverTimestamp,
  orderBy,
  where,
  query,
  doc,
  updateDoc,
  limit,
  startAfter,
  onSnapshot,
} from "firebase/firestore";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import "..//pages/allCSS.css";
import UserPromptModal from "..//..//src/components/userPrompt.js";
import App from "..//App.js";
import { useParams } from "react-router-dom";
import IncentiveSearch from "./agent2.js";
import Typewriter from "..//components/Typewriter.js";
import SSEDisplay from "..//components/SSEDisplay.js";
import renderCriteria from "..//components/criteria.js";
import RatingComponent from "..//components/RatingComponent.js";
//import LongPollDisplay from '..//components/LongPolling.js';
import DOMPurify from "dompurify";

function ThreadUnic() {
  //console.log(threadId); // This should log the actual threadId from the URL
  const navigate = useNavigate();
  const headerStyles = {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    padding: "10px",
    fontFamily: "'Poppins', sans-serif",
    textAlign: "center",
    wordSpacing: "6px",
    fontSize: "35px",
    //animation: "flash 3s infinite",
  };

  ////////////////////////////////////////////////////////////////////////////////////
  //text entry to fb db
  const [newQ1, setNewQ1] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const questionsCollectionRef = collection(db, "questions");
  const threadsCollectionRef = collection(db, "threads");
  const [lastVisible, setLastVisible] = useState(null);
  const location = useLocation();
  const { title } = location.state || { title: "Default Title" };
  const { assistant, threadId, incentiveId } = useParams();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [document, setDocument] = useState(null);
  const [submissionSource, setSubmissionSource] = useState("manual");
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [showRating, setShowRating] = useState(false);

  ///////////////////
  /////get Incentivi details to start with

  useEffect(() => {
    const fetchDocument = async () => {
      const incentiviCollectionRef = collection(db, "incentivi");
      //console.log("Fetching document with assistant:", assistant, "and incentiveId:", incentiveId); // Log assistant and incentiveId
      const incentiveQuery = query(
        incentiviCollectionRef,
        where("AssistantID", "==", assistant),
        where("ID_Incentivo", "==", incentiveId)
      );
      setLoading(true);
      try {
        const querySnapshot = await getDocs(incentiveQuery);
        if (!querySnapshot.empty) {
          //console.log("Document(s) found:", querySnapshot.docs.map(doc => doc.data()));
          // Assuming you are interested in the first matched document
          setDocument(querySnapshot.docs[0].data());
        } else {
          console.log("No such document!");
          setError("Document not found");
        }
      } catch (err) {
        console.error("Error getting document:", err);
        setError("Failed to fetch data");
      }
      setLoading(false);
    };

    fetchDocument();
  }, [assistant, incentiveId]);

  //////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    // Call getQuestionList when the component mounts
    getQuestionList();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  // The rest of your component...

  const getQuestionList = async () => {
    try {
      const userId = auth.currentUser ? auth.currentUser.uid : null;
      if (!userId) {
        console.error("User is not authenticated.");
        return;
      }

      // Create a query against the collection.
      const q = query(
        questionsCollectionRef,
        where("userId", "==", userId),
        orderBy("timestamp", "asc")
      );
      const querySnapshot = await getDocs(q);

      const filteredData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setQuestionList(filteredData);
    } catch (err) {
      console.error("Error fetching questions:", err);
    }
  };

  const onSubmitQuestion = async () => {
    try {
      if (!newQ1.trim()) {
        console.log("Empty question, exiting.");
        alert("Please enter a message.");
        return;
      }

      setSubmissionSource("manual");
      // Reset the input field
      setNewQ1("");

      const userId = auth.currentUser ? auth.currentUser.uid : null;
      //console.log("Current user ID:", userId);
      if (!userId) {
        console.error("User is not authenticated.");
        return;
      }

      //console.log("Parameters:", { assistant, threadId });
      //console.log("Database references:", { questionsCollectionRef, threadsCollectionRef });

      // Add the question to Firestore and get the reference to the newly added document
      //console.log("Attempting to add a document to questions collection...");
      const docRef = await addDoc(questionsCollectionRef, {
        q1: newQ1,
        userId: userId,
        timestamp: serverTimestamp(),
        threadId: threadId,
        response: "",
      });

      //console.log("Document added with ID:", docRef.id);
      localStorage.setItem("currentDocId", docRef.id); // Store docId in local storage

      //console.log("Attempting to add a document to threads collection...");
      const docReff = await addDoc(threadsCollectionRef, {
        userId: userId,
        threadId: threadId,
        title: title,
        assistant: assistant,
      });

      //console.log("Document added with ID:", docReff.id);

      // Call the API after successfully adding the question
      await fetchCompletion(newQ1, threadId, assistant, docRef.id);
      //checkForResponse(threadId, docRef.id);

      //getResponse(threadId, docRef.id);

      setNewQ1("");

      // Optionally refresh the question list to include the latest question
      await getQuestionList();
    } catch (err) {
      console.error("Error submitting the question:", err);
    }
  };

  useEffect(() => {
    if (readyToSubmit && newQ1.trim()) {
      onSubmitQuestion();
      setReadyToSubmit(false); // Reset the trigger
    }
  }, [readyToSubmit, newQ1]);

  const [showUserPrompt, setShowUserPrompt] = useState(false);
  useEffect(() => {
    const userHasVisited = localStorage.getItem("userHasVisited");
    if (!userHasVisited) {
      setShowUserPrompt(true);
    }
  }, []);
  const handleClose = () => {
    localStorage.setItem("userHasVisited", "true");
    setShowUserPrompt(false);
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////

  const [question, setQuestion] = useState("");
  const [docId, setDocId] = useState("");
  //const [jsonData, setJsonData] = useState(Opendata); // Ensure Opendata is defined
  const [apiResponse, setApiResponse] = useState("");
  const [messages, setMessages] = useState([
    {
      role: "system",
      content:
        "You are a helpful assistant with good general knowledge for checking facts",
      file_ids: "123",
    },
  ]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  /////message back and forth logic

  // Similar as before, sends the question to your Flask backend
  const fetchCompletion = async (question, threadId, assistant, docId) => {
    /* console.log(
      "Sending question to server:",
      question,
      threadId,
      assistant,
      docId
    ); */

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${API_BASE_URL}/api/fetchCompletionServer`;

    try {
      // Create the request body directly within the fetch call for clarity and simplicity
      const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          question: question,
          threadId: threadId,
          assistant: assistant,
          docId: docId,
        }),
      });

      /* console.log(
        "Request body:",
        JSON.stringify({
          question: question,
          threadId: threadId,
          assistant: assistant,
          docId: docId,
        })
      ); */

      //console.log("Response received. Status:", response.status);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseText = await response.text(); // Fetch the raw response text
      //console.log("Raw response text:", responseText);

      try {
        const jsonResponse = JSON.parse(responseText);
        //console.log("Parsed JSON response:", jsonResponse);

        const { thread_id } = jsonResponse;
        //console.log("Received thread_id & assistant:", thread_id, assistant);

        // Optionally update the document with the new thread ID if necessary
        if (!threadId) {
          await updateDoc(doc(questionsCollectionRef, docId), {
            threadId: thread_id,
          });
          //console.log("Updated document with new threadId.");
        }

        if (!assistant) {
          await updateDoc(doc(threadsCollectionRef, docId), {
            assistant: assistant,
          });
          console.log("Updated document with new assistant.");
        }

        // SSEDisplay(thread_id, docId);
        //console.log("Ready to display SSE stream.");
      } catch (parseError) {
        console.error("Error parsing JSON:", parseError);
      }
    } catch (error) {
      console.error("Error during fetch operation:", error);
    }
  };

  /////periodically checks for the assistant's response
  /*  const checkForResponse = async (thread_id, docId, attempt = 1) => {
    const maxAttempts = 9; // Set the maximum number of retry attempts
    console.log(`Starting attempt ${attempt} for thread_id: ${thread_id} with docId: ${docId}`); // Log attempt details

    setIsLoading(true);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    try {
        const response = await fetch(`${API_BASE_URL}/api/getResponse`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ thread_id }),
        });

        console.log(`HTTP response received for attempt ${attempt}: ${response.status}`); // Log the HTTP response status

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log(`Server's response data for attempt ${attempt}:`, data); // Log the data received from the server

        if (data.status === "completed") {
            console.log(`Complete response received: ${data.response}`);
            await updateDoc(doc(questionsCollectionRef, docId), {
                response: data.response // Update to use data.response here, matching your payload structure
            });
            setApiResponse(data.response); // Log and update response
            setIsLoading(false);
        } else if (attempt < maxAttempts) {
            console.log(`Incomplete response received, retrying attempt ${attempt + 1} of ${maxAttempts}`);
            setTimeout(() => checkForResponse(thread_id, docId, attempt + 1), 5000);
        } else {
            console.log(`Maximum attempts reached without complete response, stopping.`);
            setApiResponse("Response timeout, please try again.");
            setIsLoading(false);
        }
    } catch (error) {
        //console.error("Error fetching response:", error);
        setApiResponse("Error fetching response, please try again.");
        setIsLoading(false);
    }
}; */

  /* new getResponse*/
  /* const getResponse = async (threadId, docId) => {
  try {
      const response = await fetch('/api/getResponse', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              threadId,
              docId,
          }),
      });

      const data = await response.json();
      if (response.ok) {
          console.log('Response received and processed:', data);
      } else {
          console.error('Error from server:', data);
      }
  } catch (error) {
      console.error('Error fetching response:', error);
  }
}; */

  const textAreaRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    //console.log("Submitting question:", newQ1);

    if (submissionSource === "questionButton") {
      // If the submission source is the question button, wait for the state update
      setTimeout(() => {
        // Using setTimeout as a simple way to wait for the next event loop
        onSubmitQuestion();
      }, 0);
    } else {
      // If the user manually submits, proceed immediately
      setReadyToSubmit(true);
    }

    // Reset the textarea's height directly using the ref
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
    }
  };

  const [currentThreadId, setCurrentThreadId] = useState(null);
  const [lastThreads, setLastThreads] = useState([]);

  const filteredQuestions = questionList.filter(
    (question) => question.threadId === threadId
  );

  const [isLoading, setIsLoading] = useState(false);

  const [threadTitle, setThreadTitle] = useState("");

  useEffect(() => {
    const fetchThreadTitle = async () => {
      if (threadId) {
        // Ensure threadId is not undefined or empty
        const threadsCollectionRef = collection(db, "threads");
        const q = query(
          threadsCollectionRef,
          where("threadId", "==", threadId)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Assuming each threadId value is unique and only one document should match
          const docSnap = querySnapshot.docs[0];
          setThreadTitle(docSnap.data().title); // Update state with the fetched title
        } else {
          //console.log("No such document with threadId:", threadId);
        }
      }
    };

    fetchThreadTitle();
  }, [threadId]); // Re-fetch whenever threadId changes

  //////////////////////////////////////////////////////////////////////////////////////////////////
  ///////FORMATTING
  const formatDescription = (desc) => {
    if (typeof desc === "string") {
      desc = desc.trim().replace(/\r\n?/g, "\n"); // Normalize line endings

      let formattedText = ""; // Initialize the formatted text.
      let paragraphs = desc.split(/---\n*/); // Split by '---' for new paragraphs.

      paragraphs.forEach((paragraph, index) => {
        if (index > 0) {
          formattedText += "<p>"; // Start a new paragraph for each new section defined by '---'
        }

        // Handle bold text and convert line breaks within a paragraph into <br>
        let lines = paragraph.split("\n");
        lines = lines.map((line) => {
          if (line.startsWith("###")) {
            // Remove '###' and format the text
            const textWithoutHash = line.substring(3).trim(); // Remove the '###' and any leading/trailing whitespace
            return `<span style="font-size: 20px;">${textWithoutHash}</span>`; // Apply styling directly
          } else {
            // Replace "**text**" with "<strong>text</strong>"
            return line.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
          }
        });

        formattedText += lines.join("<br>"); // Join lines with <br> tags

        if (index > 0) {
          formattedText += "</p>"; // Close paragraph tag only if it's not the first section
        }
      });

      // Sanitize the final HTML string to ensure it's safe to render
      const safeHtml = DOMPurify.sanitize(formattedText, {
        USE_PROFILES: { html: true },
      });

      return safeHtml; // Return the sanitized HTML
    }
    return "No description available"; // Fallback if input is not a string
  };

  const formatDate = (dateString) => {
    return dateString ? dateString.split("T")[0] : "N/A"; // Default to 'N/A' if dateString is undefined
  };

  ////////////////////////////////////////////////////////////////////////////////////////

  const [criteriaStates, setCriteriaStates] = useState({});
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (document) {
      const initialStates = {};
      for (let i = 1; i <= 15; i++) {
        const criteriaKey = `Criteria_${i}`;
        if (
          document[criteriaKey] &&
          document[criteriaKey] !== "Not specified"
        ) {
          initialStates[criteriaKey] = "Non"; // Default to "Non" instead of leaving it undefined
        }
      }
      setCriteriaStates(initialStates);
    }
  }, [document]);

  useEffect(() => {
    //console.log("Checking all criteria states:", criteriaStates);
    const allSelectedSi = Object.values(criteriaStates).every(
      (state) => state === "Si"
    );
    //console.log("Are all selected 'Si'? :", allSelectedSi);
    setShowPopup(allSelectedSi);
  }, [criteriaStates]);

  const handleButtonClick = (
    buttonType,
    criterionKey,
    criterionDescription
  ) => {
    if (buttonType === "?") {
      const fullText = `cosa significa esattamente questo criterio: ${criterionDescription}`;
      setNewQ1(fullText);
      setSubmissionSource("questionButton");
      if (textAreaRef.current) {
        textAreaRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      // Assuming handleSubmit takes an event argument
      handleSubmit(new Event("submit"));
    } else {
      setCriteriaStates((prevStates) => {
        const newState = {
          ...prevStates,
          [criterionKey]: buttonType,
        };
        //console.log(`Updated criteria state for ${criterionKey} to ${buttonType}`, newState);
        return newState;
      });
    }
  };

  const renderCriteria = () => {
    let criteriaComponents = [];
    for (let i = 1; i <= 15; i++) {
      const criteriaKey = `Criteria_${i}`;
      if (document[criteriaKey] && document[criteriaKey] !== "Not specified") {
        criteriaComponents.push(
          <Criterion
            key={criteriaKey}
            criterionName={criteriaKey}
            description={document[criteriaKey]}
            selected={criteriaStates[criteriaKey]}
            onButtonClick={(buttonType) =>
              handleButtonClick(buttonType, criteriaKey, document[criteriaKey])
            }
          />
        );
      }
    }
    return criteriaComponents;
  };

  const Criterion = ({
    criterionName,
    description,
    selected,
    onButtonClick,
  }) => {
    const buttonStyle = (buttonType) => ({
      backgroundColor:
        buttonType === selected
          ? buttonType === "Si"
            ? "green"
            : buttonType === "?"
            ? "orange"
            : "red"
          : "",
      color: buttonType === selected ? "white" : "black",
      margin: "0 4px",
      cursor: "pointer",
    });

    return (
      <div className="criteria">
        <p>{description}</p>
        <div>
          <button style={buttonStyle("Si")} onClick={() => onButtonClick("Si")}>
            Si
          </button>
          <button
            style={buttonStyle("Non")}
            onClick={() => onButtonClick("Non")}
          >
            Non
          </button>
          <button style={buttonStyle("?")} onClick={() => onButtonClick("?")}>
            Chiede all'agente
          </button>
        </div>
      </div>
    );
  };

  const handleNavigate = () => {
    const threadId = localStorage.getItem("currentThreadId");
    if (threadId && assistant && incentiveId) {
      navigate(`/threadUnic/${assistant}/${threadId}/${incentiveId}/Apply`);
    } else {
      console.error("Thread ID or Assistant ID is missing");
    }
  };

  function CustomTooltip({ children, tooltipText }) {
    return (
      <div className="tooltip-box">
        {children}
        <span className="tooltip-text">{tooltipText}</span>
      </div>
    );
  }

  const handleRatingSubmit = async (rating) => {
    const docId = localStorage.getItem("currentDocId");
    if (docId) {
      const docRef = doc(questionsCollectionRef, docId);
      try {
        await updateDoc(docRef, {
          feedback: rating,
        });
        console.log("Document updated with rating:", rating);
      } catch (error) {
        console.error("Error updating document:", error);
      }
    } else {
      console.error("No doc ID found in localStorage");
    }
  };

  useEffect(() => {
    setShowRating(true);
  }, []);

  const [fullResponse, setFullResponse] = useState(null);

  const [doceId, setDoceId] = useState(localStorage.getItem("currentDocId")); // Initially fetch from local storage

  // Polling local storage for changes in docId
  useEffect(() => {
    const interval = setInterval(() => {
      const currentDocId = localStorage.getItem("currentDocId");
      if (doceId !== currentDocId) {
        setDoceId(currentDocId); // Update state if there's a change
      }
    }, 1000); // checks every second

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [doceId]);

  // Firestore document listener effect
  useEffect(() => {
    if (doceId) {
      const docRef = doc(db, "questions", doceId);

      const unsubscribe = onSnapshot(
        docRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data.response) {
              // Check if response field is populated
              //console.log("Response updated:", data.response);
              setFullResponse(data.response); // Store the full response in state
            } else {
              //console.log("Response field is not yet populated.");
              setFullResponse(null); // Reset if response is not ready
            }
          } else {
            console.log("No such document!");
          }
        },
        (error) => {
          console.error("Error listening to the document:", error);
        }
      );

      return () => unsubscribe(); // Clean up the listener when the component unmounts or doceId changes
    }
  }, [doceId]);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////start of JSX

  return (
    <div className="threadunic-container">
      {/*   <button
    onClick={() => navigate("/search")}
    className="threadunic-button"
  >
    🔙
  </button> */}
      <div
        style={{
          position: "relative",
          textAlign: "left",
          border: "none",
          marginRight: "30px",
          marginTop: "10px",
        }}
      >
        <div>
          {document ? (
            <div>
              <CustomTooltip tooltipText="Il punteggio è una valutazione ponderata che tiene conto dei benefici chiave dell'incentivo, della complessità del processo di applicazione e dell'ambito geografico">
                <h2 style={{ marginTop: "10px", marginLeft: "5px" }}>
                  Ottima scelta! {document.RatingInterest}
                </h2>
              </CustomTooltip>
              <div className="incentivethread-subdetails">
                <p style={{ fontWeight: "bold" }}>
                  <strong> </strong> {document.Titolo}
                </p>
                <p>
                  <strong>Apertura:</strong>{" "}
                  {formatDate(document.Data_apertura)}
                </p>
                <p>
                  <strong>Chiusura:</strong>{" "}
                  {formatDate(document.Data_chiusura)}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  <strong></strong> {document.KeyBenefit}
                </p>
              </div>
              {/* <p className="criteria"><strong>Descrizione:</strong> <p>{formatDescription(document.Descrizione)}</p></p>*/}
            </div>
          ) : (
            <p>No data available.</p>
          )}
        </div>
        <h2 style={{ marginTop: "50px", marginLeft: "5px" }}>Criteri:</h2>
        <h2 style={{ fontSize: "20px", marginLeft: "5px" }}>
          Prima di candidarci controlliamo se soddisfi i criteri
        </h2>
        <h2 style={{ fontSize: "20px", marginLeft: "5px" }}>
          {" "}
          Seleziona se soddisfi i criteri (Si) o meno (NON).
        </h2>
        <h2 style={{ fontSize: "20px", marginLeft: "5px" }}>
          {" "}
          Per qualsiasi dubbio (?) per qualsiasi dubbio potete chiedere al
          nostro agente dedicato
        </h2>
        <div>
          {!isAuthenticated && <UserPromptModal />}
          <div
            style={{
              margin: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          ></div>
          <div>
            {document ? (
              <div className="incentivethread-details">
                <div>
                  {renderCriteria()}
                  {showPopup && (
                    <div
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "black",
                        padding: "20px",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.25)",
                      }}
                    >
                      <p>
                        Tutti i criteri selezionati come "Si". Procedi con la
                        tua candidatura.
                      </p>
                      <button style={{cursor: "pointer"}}
                        onClick={() => handleNavigate(document.thread_id)}
                      >
                        Apply
                      </button>
                      <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                  )}
                </div>
                {/* <p className="criteria"><strong>Descrizione:</strong> <p>{formatDescription(document.Descrizione)}</p></p>*/}
              </div>
            ) : (
              <p>No data available.</p>
            )}
          </div>
        </div>
        <div className="App">
          {/*   <SimpleQuestionDisplay threadId={threadId} /> */}
        </div>
        <div className="full-display">
          <div className="questions-display">
            {filteredQuestions.map((question, index) => {
              // Convert timestamp to JavaScript Date object
              const questionDate = question.timestamp?.toDate();

              // Format the date as a simple string for comparison (ignoring time part)
              const questionDateString = questionDate?.toLocaleDateString();

              // Check if this is the first question of a new day
              const isFirstQuestionOfDay =
                index === 0 ||
                filteredQuestions[index - 1]?.timestamp
                  ?.toDate()
                  ?.toLocaleDateString() !== questionDateString;

              const isLastQuestion = index === filteredQuestions.length - 1;

              return (
                <div key={question.id} className="question-item">
                  {/* Display the timestamp if it's the first question of the day */}
                  {isFirstQuestionOfDay && (
                    <div className="date-display">
                      <small>{questionDateString}</small>
                    </div>
                  )}
                  <div className="question-text">
                    <div style={{ lineHeight: "1.6", background: "#7e8ee6", padding: "8px", borderRadius: "5px"   }}>
                     {/*  <strong>User:</strong> */} {question.q1}
                    </div>
                  </div>
                  <div
                    className="generic-response"
                    style={{ lineHeight: "1.6", marginTop: "20px", background: "#394896", padding: "8px", borderRadius: "5px" }}
                  >
                    {/* <strong>Agent:</strong> */}{" "}
                    {isLastQuestion &&
                      (!fullResponse ? (
                        <SSEDisplay
                          threadId={question.threadId}
                          docId={question.id}
                          setApiResponse={setApiResponse}
                          setIsLoading={setIsLoading}
                        />
                      ) : (
                        // Using dangerouslySetInnerHTML to inject the HTML
                        <div
                          dangerouslySetInnerHTML={{
                            __html: formatDescription(fullResponse),
                          }}
                        />
                      ))}
                    {!isLastQuestion && question.response && (
                      <div>
                        {question.response
                          .split(". ")
                          .map((sentence, idx, arr) => (
                            <p key={idx}>
                              {sentence + (idx < arr.length - 1 ? "." : "")}
                            </p>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          {showRating && (
            <RatingComponent onSubmitRating={handleRatingSubmit} />
          )}
        </div>
        <div>
          <div className="input-container">
            <form onSubmit={handleSubmit} className="input-container">
              <textarea
                ref={textAreaRef}
                placeholder="Scriverci qui..."
                onChange={(e) => setNewQ1(e.target.value)}
                value={newQ1}
                className="chatbox-style"
                maxLength="1000"
                required
                rows="1"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
                onInput={(e) => {
                  if (textAreaRef.current) {
                    textAreaRef.current.style.height = "auto";
                    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
                  }
                }}
              />

              <button type="submit" className="submit-button">
                ➡️
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThreadUnic;
