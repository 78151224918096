import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
//import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "comm-mvp.firebaseapp.com",
  projectId: "comm-mvp",
  storageBucket: "comm-mvp.appspot.com",
  messagingSenderId: "74522388396",
  appId: "1:74522388396:web:fd34c1f69a90c6905a7c09",
  measurementId: "G-LHT55B6L7J"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

export const db = getFirestore(app);
export const storage = getStorage(app);

//export const analytics = getAnalytics(app);
