import React, { useState, useEffect } from 'react';
import { db } from "../config/firebase.js";
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { useCart } from './cartContext.js';
import { useNavigate } from "react-router-dom";
import { auth } from '../config/firebase.js'; // Ensure this is correctly imported

export default function Cart() {
    const navigate = useNavigate();
    const handleCheckout = () => navigate('/checkout');
    const [serviceData, setServiceData] = useState(null);
    const { removeFromCart } = useCart(); // Assuming you have this function in your cart context

    useEffect(() => {
        const fetchData = async () => {
            try {
                const q = query(collection(db, 'services'), where('serviceName', '==', 'Essential'));
                const querySnapshot = await getDocs(q);

                const services = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setServiceData(services);
            } catch (error) {
                console.error("Error fetching services: ", error);
            }
        };

        fetchData();
    }, []);

    if (!serviceData) {
        return <div>Loading...</div>;
    }

    const addService = async (serviceId) => {
        try {
            const userId = auth.currentUser ? auth.currentUser.uid : null;
            if (!userId) {
                console.error("User is not authenticated.");
                return;
            }

            const userDocRef = doc(db, 'users', userId);
            await updateDoc(userDocRef, {
                service: serviceId
            });

            console.log("Service added successfully to user profile.");
        } catch (error) {
            console.error("Error adding service to user profile:", error);
        }
    };

    return (
        <div style={{ margin: "20px" }}>
            <h1>Your Cart</h1>
            <ul>
                {serviceData.map((item, index) => (
                    <li key={index}>
                        <div>Service Nome: {item.serviceName}</div>
                        <div>Service Descrizione: {item.serviceDescription}</div>
                        <div>Service Tipo: {item.serviceType}</div>
                        <div>Prezzo: ${item.servicePrice?.toFixed(2)}</div>
                        <div>Service ID: {item.id}</div>
                        <button
                            style={{ marginRight: "20px" }}
                            className="subscription-button"
                            onClick={() => addService(item.id)}
                        >
                            Aggiungi Gratis al mio profilo
                        </button>
                        <button
                            style={{ marginRight: "20px" }}
                            className="subscription-button"
                            onClick={() => removeFromCart(item.id)}
                        >
                            Remove
                        </button>
                    </li>
                ))}
            </ul>
            <button onClick={handleCheckout}>Go to Checkout</button>
        </div>
    );
}
