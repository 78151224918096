///imports this is the create page///
import React, { useEffect, useState, useRef, useMemo } from "react";
import { db, auth } from "../config/firebase.js";
import { getDocs, collection, addDoc, Timestamp } from "firebase/firestore";
import ".//about.css";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
//import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
//import Places from "./mapview"; // Import your API key
import { storage } from "../config/firebase.js";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";

export default function Initmaps() {
  const [feedbackList, setFeedbackList] = useState([]);
  const textContainerRef = useRef(null);

  // New Feedback States
  const [newFB1, setNewFB1] = useState("");
  const [newFB2, setNewFB2] = useState("");
  const [newFB3, setNewFB3] = useState("");
  const [newFB4, setNewFB4] = useState("");
  const [newFB5, setNewFB5] = useState("");
  const [newFB6, setNewFB6] = useState("");
  const [newFB7, setNewFB7] = useState("");
  const [newFB8, setNewFB8] = useState("");

  const feedbacksCollectionRef = collection(db, "feedback");

  const getFeedbackList = async () => {
    try {
      const data = await getDocs(feedbacksCollectionRef);
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setFeedbackList(filteredData);
    } catch (err) {
      console.error(err);
    }
  };

  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmitFeedback = async () => {
    try {
      // Check if any required fields are empty
      if (
        !newFB1 ||
        !newFB2 ||
        !newFB3 ||
        !newFB4 ||
        !newFB5 ||
        !newFB6 ||
        !newFB7 ||
        !newFB8 
      ) {
        alert("Please fill out all required fields before submitting.");
        return; // Don't submit the form if any required fields are empty
      }


      await addDoc(feedbacksCollectionRef, {
        FB1: newFB1,
        FB2: newFB2,
        FB3: newFB3,
        FB4: newFB4,
        FB5: newFB5,
        FB6: newFB6,
        FB7: newFB7,
        FB8: newFB8,
      });
      getFeedbackList();
      setIsSubmitted(true);
    } catch (err) {
      console.error("missing an input.");
    }
  };

  const navigate = useNavigate();

  // const updateEventLocation = (location) => {
  //   setNewEventLocation(location);
  // };

  const newFB1Options = [
    "Campus Name 1",
    "Campus Name 2",
    "Campus Name 3",
  ];

  const newFB2Options = [
    "10-14th June",
    "17th-21st June",
    "24th-28th June",
    "1st-5th July",
    "8th-12th July",
    "15th-19th July",
    "22nd-26th July",
  ];

  const newFB4Options = ["Pranzo Sacco", "Mensa"];
  const newFB5Options = ["Si", "Non"];
  const newFB6Options = ["1", "2", "3", "4", "5"];
  const newFB7Options = ["Si", "Non"];

  const popupStyle = {
    position: "fixed",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#092f7a",
    color: "black",
    border: "1px solid black",
    borderRadius: "5px",
    boxShadow: "0 0 30px rgba(0, 0, 0, 0.2)",
    zIndex: 9999,
    textAlign: "left",
    overflow: "hidden",
    justifyContent: "space-between",
    display: "block",
  };

//   const myevents = async () => {
//     try {
//       navigate("/myevents");
//     } catch (err) {
//       console.error(err);
//     }
//   };

  /////////////////////////
  /////places stuff///////////
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "Initmap",
    requestOptions: {
      componentRestrictions: { country: "IT" },
    },
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = (val) => {
    setValue(val, false);
    //setNewFeedbackLocation(val); 
    clearSuggestions();
  };



  return (
    <div className="About about-section">
      <div
        style={{
          width: "80%",
          textAlign: "center",
          marginLeft: "10%",
          marginRight: "10%",
        }}
      >
        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "right" }}
        >
          {" "}
          What is the Name of the campus? please state other{" "}
        </div>
        <select
          value={newFB1}
          onChange={(e) => setNewFB1(e.target.value)}
          className="event-input-field side-by-side"
        >
          <option value="">Select</option>
          {newFB1Options.map((FB1, index) => (
            <option key={index} value={FB1}>
              {FB1}
            </option>
          ))}
        </select>
        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "right" }}
        >
          {" "}
          Which week did the kids attend?{" "}
        </div>
        <select
          value={newFB2}
          onChange={(e) => setNewFB2(e.target.value)}
          className="event-input-field side-by-side"
        >
          <option value="">Select</option>
          {newFB2Options.map((FB2, index) => (
            <option key={index} value={FB2}>
              {FB2}
            </option>
          ))}
        </select> 
        <div
          className="side-by-side"
          style={{
            color: "white",
            textAlign: "right",
            backgroundColor: "black",
          }}
        >
          {" "}
          How did you find the interation with staff?{" "}
        </div>
        <input
          placeholder="Staff...max 100 chars"
          onChange={(e) => setNewFB3(e.target.value)}
          className="event-input-field side-by-side"
          rows="4"
          maxLength="100"
          required
        />
        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "right" }}
        >
          {" "}
          Was it pranzo sacco o mensa?{" "}
        </div>
        <select
          value={newFB4}
          onChange={(e) => setNewFB4(e.target.value)}
          className="event-input-field side-by-side"
        >
          <option value="">Select</option>
          {newFB4Options.map((FB4, index) => (
            <option key={index} value={FB4}>
              {FB4}
            </option>
          ))}
        </select>
        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "right" }}
        >
          {" "}
          Do you feel it was correctly priced?{" "}
        </div>
        <select
          value={newFB5}
          onChange={(e) => setNewFB5(e.target.value)}
          className="event-input-field side-by-side"
        >
          <option value="">Select</option>
          {newFB5Options.map((FB5, index) => (
            <option key={index} value={FB5}>
              {FB5}
            </option>
          ))}
        </select>
        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "right" }}
        >
          {" "}
          How would you rate the campus on a scale from 1-5?{" "}
        </div>
        <select
          value={newFB6}
          onChange={(e) => setNewFB6(e.target.value)}
          className="event-input-field side-by-side"
        >
          <option value="">Select</option>
          {newFB6Options.map((FB6, index) => (
            <option key={index} value={FB6}>
              {FB6}
            </option>
          ))}
        </select>
        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "right" }}
        >
          {" "}
          Would the kids attend next year if it was on?{" "}
        </div>
        <select
          value={newFB7}
          onChange={(e) => setNewFB7(e.target.value)}
          className="event-input-field side-by-side"
        >
          <option value="">Select</option>
          {newFB7Options.map((FB7, index) => (
            <option key={index} value={FB7}>
              {FB7}
            </option>
          ))}
        </select>
        <div
          className="side-by-side"
          style={{ color: "white", textAlign: "right" }}
        >
          {" "}
          Any other comments?{" "}
        </div>
        <input
          placeholder="Write here"
          onChange={(e) => setNewFB8(e.target.value)}
          className="event-input-field side-by-side"
          required
          rows="5"
        />
        

       
       
        <button
          onClick={onSubmitFeedback}
          style={{
            color: "white",
            margin: "20px",
            border: "1px solid white",
            borderRadius: "5px",
            backgroundColor: "black",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          Submit Feedback
        </button>
        
        {/* Modal for Success Message and Options */}
        {isSubmitted && (
          <div className="modal">
            <div className="modal-content" style={popupStyle}>
              <p>Great! Your feedback was submitted.</p>
              <button
                onClick={() => {
                  window.location.reload();
                  navigate("/campusFeedback");
                }}
              >
                Submit Another
              </button>
              <button onClick={() => navigate("/")}>
                Go Home
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function loadScript(url) {
  // Check if the script is already included
  if (!window.googleMapsScriptLoaded) {
    var index = window.document.getElementsByTagName("script")[0];
    var script = window.document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);

    // Set a flag to indicate that the script has been loaded
    window.googleMapsScriptLoaded = true;
  }
}
