import React, { useEffect, useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import Typewriter from '../components/Typewriter.js';
import { db } from '../config/firebase.js'; 
import "..//pages/allCSS.css";

function SSEDisplay({ threadId, setApiResponse, setIsLoading }) {
  const [message, setMessage] = useState('');
  const [completed, setCompleted] = useState(false);
  const [messageQueue, setMessageQueue] = useState([]);
  const [isLoading, setLoading] = useState(true); 

  useEffect(() => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const url = `${API_BASE_URL}/api/streamResponse?thread_id=${encodeURIComponent(threadId)}`;
    const eventSource = new EventSource(url);

    eventSource.onmessage = function(event) {
      //console.log("Received SSE data:", event.data); // Log raw data received from SSE
      try {
        const data = JSON.parse(event.data);
        //console.log(`Data received: ${data.response}`); // Log each received message
        if (data.status === 'delta') {
          if (data.response) {  // Check if data.response is truthy before appending
            setMessage(prev => prev + data.response);
          }
          setLoading(false);
        } else if (data.status === 'completed') {
          if (data.response) {  // Check if data.response is truthy before appending
            setMessage(prev => prev + data.response);
          }
          setCompleted(true);
          setLoading(false);
          eventSource.close();
        }
      } catch (error) {
        console.error("Error handling SSE data:", error);
        setIsLoading(false);
        setMessage('Error loading response.');  // Handle parsing errors
        setLoading(false);
        eventSource.close();
      }
    };
    
    

    eventSource.onerror = function(error) {
      //console.error("EventSource encountered an error:", error);
      setIsLoading(false);
      setMessage('Error loading response.');
      setLoading(false);
      eventSource.close();
    };

    return () => eventSource.close();
  }, [threadId, setIsLoading]);

  useEffect(() => {
    const handleQueue = () => {
      if (messageQueue.length > 0) {
        const nextMessage = messageQueue.shift(); // Dequeue the next message
        setMessage(prev => prev + nextMessage); // Update message
      }
    };

    const intervalId = setInterval(handleQueue, 2); // Adjust interval as needed

    return () => clearInterval(intervalId);
  }, [messageQueue]);

  useEffect(() => {
    if (completed) {
      const docId = localStorage.getItem('currentDocId');
      if (docId) {
        //saveResponseToFirebase(message, docId, setApiResponse, setIsLoading);
      }
    }
  }, [completed, message, setApiResponse, setIsLoading]);

  const formatDescription = (desc) => {
    if (typeof desc === "string") {
      desc = desc.trim();
      let formattedText = ""; // Initialize formatted text string
  
      // Splitting the description at each new line
      const lines = desc.split(/\n+/);
      lines.forEach((line, index) => {
        line = line.trim();
        if (line === "") {
          // Add a newline for empty lines
          formattedText += "\n";
          return;
        }
  
        // Handle "###" by adding a newline and some emphasis
        if (line.startsWith('###')) {
          formattedText += "\n\n" + line.replace(/###\s*/, '') + "\n";
        } else if (line.includes('**')) {
          // Create a new paragraph for lines with "**"
          // Ensure it starts on a new line and add a newline after
          if (!formattedText.endsWith("\n")) formattedText += "\n"; // Ensure starts on a new line
          formattedText += line.replace(/\*\*/g, '') + "\n\n"; // Replace "**" with nothing and add new line after
        } else {
          // Handle normal lines, preserving bullet points
          formattedText += line + "\n";
        }
      });
  
      return formattedText;
    }
    return "No description available";
  };
  
  

  // Use effect or useMemo here if your message is expected to change or if formatting can be expensive
  const formattedMessage = formatDescription(message);
 
  return <div>
  {isLoading ? (
    <div className="spinner-tut">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>  // Updated spinner with circles
  ) : ( 
    <Typewriter text={formattedMessage || ''} speed={25} />
  )}
</div>;
}

export default SSEDisplay;
