import React, { useState } from 'react';

const ToggleSwitch = ({ label, onChange }) => {
    const [state, setState] = useState(null);  // null for 'All', true for 'Y', false for 'N'

    const toggleSwitch = () => {
        const newState = state === null ? true : state === true ? false : null;
        setState(newState);
        onChange(newState === null ? "" : newState ? "Y" : "N");  // Pass up "", "Y", or "N"
        //console.log("Toggle is now:", newState === null ? "All (Grey)" : newState ? "On (Green)" : "Off (Red)");
    };

    return (
        <div style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "10px",
            alignItems: "center"
        }}>
            <label style={{
                marginRight: "10px",
                minWidth: "150px"
            }}>{label}:</label>
            <div onClick={toggleSwitch} style={{
                width: "50px",
                height: "24px",
                backgroundColor: state === null ? "#ccc" : state ? "#4CAF50" : "#ff6347", // Grey for All, Green for Y, Red for N
                borderRadius: "12px",
                position: "relative",
                cursor: "pointer",
                transition: "background-color 0.3s"
            }}>
                <div style={{
                    position: "absolute",
                    top: "2px",
                    left: state === null ? "0" : state ? "26px" : "2px", // Center for All, Right for Y, Left for N
                    width: "20px",
                    height: "20px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    transition: "left 0.3s"
                }} />
            </div>
        </div>
    );
};

export default ToggleSwitch;
